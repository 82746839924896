import i18n from '@/libs/i18n'
import { getAuth0Instance as authInstance } from '@/plugins/auth0'

export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users/users-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Users'),
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/users/users-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Edit User'),
      action: 'update',
      resource: 'users',
      store: {
        subscribe: 'users/SET_CURRENT',
        retrieve: 'users/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Users'),
          to: '/users/list',
        },
        {
          text: '',
          field: 'email',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (authInstance().me.snapshot && to.params.id === authInstance().me.snapshot.id) {
        next({ name: 'account-settings' })
      }

      next()
    },
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/users/users-view/View.vue'),
    meta: {
      pageTitle: i18n.t('View User'),
      action: 'read',
      resource: 'users',
      store: {
        subscribe: 'users/SET_CURRENT',
        retrieve: 'users/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Users'),
          to: '/users/list',
        },
        {
          text: '',
          field: 'email',
          active: true,
        },
        {
          text: i18n.t('View'),
          active: true,
        },
      ],
    },
  },
]
