import {
  getDocs,
  getDoc,
  doc,
  updateDoc,
  orderBy,
  query,
  where,
  setDoc,
  collection,
  writeBatch,
} from 'firebase/firestore'
import { getUnix } from '@/libs/date-format'
import { db } from '@/libs/firebase'
import { uniqBy } from 'lodash'
import { constructQuery } from '@core/utils/utils'
import { RevisionEventType } from '@core/revisions/revisions'
import { nextTick } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import router from '@/router'
import { allowed, FEATURES } from '@/auth'

export default {
  namespaced: true,
  state: {
    list: [],
    filteredList: [],
    listRelated: [],
    current: {},
    languages: {},
    listView: localStorage.getItem('novti-pages-list-view') || 'grid',
    translations: {},
    thumbnailPlaceholder: process.env.VUE_APP_PAGES_THUMBNAIL_PLACEHOLDER,
  },
  getters: {
    getCurrent: state => state.current,
    getListView: state => state.listView,
    getTranslations: state => state.translations,
    getThumbnailPlaceholder: state => state.thumbnailPlaceholder,
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getFilteredList: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getById: state => id => state.list.map(docRef => ({ snapshot: docRef, data: { ...docRef.data(), id: docRef.id } })).filter(el => !el.data.deletedAt).find(item => item.snapshot.id === id),
    getRelated: state => state.listRelated.map(docRef => ({ ...docRef.data(), id: docRef.id })).map(({ schema, ...item }) => item).filter(el => !el.deletedAt),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      const { docs, pagination } = payload

      state.list = (!pagination) ? docs : uniqBy([...state.list, ...docs], 'id')
    },
    UPDATE_LIST_RELATED(state, payload) {
      state.listRelated = payload
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.list = payload
    },
    UPDATE_TRANSLATIONS(state, payload) {
      state.translations = payload
    },
    SET_CURRENT(state, payload) {
      state.current = {
        snapshot: payload,
        data: { ...payload.data(), id: payload.id },
      }
    },
    UPDATE_LIST_VIEW(state, payload) {
      localStorage.setItem('novti-pages-list-view', payload)
      state.listView = payload
    },
  },
  actions: {
    /**
     * Creates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.PAGES_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your {title} limit', { title: i18n.t('page limit') }),
            variant: 'danger',
          })

          return
        }

        const { pages } = tenantCtx()

        // This is required if we want to filter on `deletedAt == null`, as per Firestore query limitations
        // eslint-disable-next-line no-param-reassign
        payload.deletedAt = null
        payload.published = false

        const docRef = doc(pages)

        setDoc(docRef, payload)
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_PAGES,
              id: docRef.id,
              newValue: payload,
              previousValue: {},
            })
            store.dispatch('notify', { title: i18n.t('Great!'), body: i18n.t('A new {title} has been created 🚀', { title: i18n.t('page') }) })
            resolve(docRef)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the {title}', { title: i18n.t('page') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches all
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()

        const { queryOptions, pagination = false } = payload

        const q = constructQuery(pages, 'createdAt', {
          ...queryOptions,
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', { docs: querySnapshot.docs, pagination })
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('pages') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetch all the translations by the given id
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {string} id
     *
     * @returns {Promise}
     */
    fetchAllTranslations({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()

        getDocs(collection(pages, id, 'translations')).then(querySnapshot => {
          const textContentsPerLanguage = querySnapshot.docs.reduce((acc, curr) => {
            acc[curr.id] = curr.data()

            return acc
          }, {})

          commit('UPDATE_TRANSLATIONS', textContentsPerLanguage)
          resolve(textContentsPerLanguage)
        }).catch(error => {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('translations') }),
            variant: 'danger',
          })

          reject(error)
        })
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()

        const q = constructQuery(pages, 'createdAt', {
          ...queryOptions,
          where: [...queryOptions.where, where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_FILTERED_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('pages') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the given ID
     *
     * @param {string} id The document ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()
        const docRef = doc(pages, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            if (docSnapshot.data().deletedAt) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('page') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID
     *
     * @param {string} id
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates the current document ref by the given payload
     *
     * @param {Object} obj
     * @param {Object} obj.dispatch
     * @param {Object} obj.state
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    updateCurrent({ dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          // Lets extract the translations from the payload
          const { translations = {}, ...data } = payload
          const deleteTranslations = {}

          const newValue = {
            ...state.current.data,
            ...data,
          }

          Object.keys(translations ?? {}).forEach(translation => {
            if (data?.supportedLanguages === null || !data.supportedLanguages.includes(translation)) {
              deleteTranslations[translation] = true
            }
          })

          if (data?.supportedLanguages === null && Object.keys(state.translations).length > 0) {
            Object.keys(state.translations).forEach(translation => {
              deleteTranslations[translation] = true
            })
          }

          await updateDoc(state.current.snapshot.ref, {
            ...newValue,
            updatedAt: getUnix(),
          })

          const { id } = state.current.snapshot

          handlePageUpdateRevision(state, newValue, id)
          await dispatch('updateTranslations', {
            id,
            translations: translations ?? {},
            deleteTranslations,
          })

          store.dispatch('notify', { body: i18n.t('{title} has been updated', { title: i18n.t('Page') }) })

          nextTick(() => {
            dispatch('fetchCurrent', id) // Update the current data as well
            resolve(true)
          })
        } catch (error) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong updating the {title}', { title: i18n.t('page') }),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },

    /**
     * Update the translations
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    updateTranslations({}, payload) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()

        try {
          const { id, translations, deleteTranslations } = payload
          let updateBatch = false

          // Nothing to store, just resolve
          if (Object.keys(translations).length === 0 && Object.keys(deleteTranslations).length === 0) {
            resolve(true)

            return
          }

          // Lets open a batch write call against Firestore
          const batch = writeBatch(db)

          const translationsCollection = collection(pages, id, 'translations')

          Object.keys(translations).forEach(language => {
            const docRef = doc(translationsCollection, language)

            updateBatch = true

            batch.set(docRef, translations[language])
          })

          Object.keys(deleteTranslations).forEach(language => {
            const docRef = doc(translationsCollection, language)

            updateBatch = true

            batch.delete(docRef)
          })

          if (!updateBatch) {
            resolve(true)

            return
          }

          batch.commit().then(() => resolve(true))
        } catch (error) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong updating the {title}', { title: i18n.t('translations') }),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },

    /**
     * Soft deletes a document
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    softDelete({}, payload) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()
        const docRef = doc(pages, payload.id)

        updateDoc(docRef, {
          deletedAt: getUnix(),
        })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_PAGES,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })

            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Duplicates a document
     *
     * @param {Object}
     * @param {string} id The id of the document to duplicate
     *
     * @returns {Promise}
     */
    duplicate({}, id) {
      return new Promise(async (resolve, reject) => {
        if (!allowed(FEATURES.PAGES_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your {title} limit', { title: i18n.t('page limit') }),
            variant: 'danger',
          })

          return
        }

        try {
          const { pages } = tenantCtx()
          const docRef = doc(pages, id)
          const timestamp = getUnix()
          const randomNumber = Math.floor(Math.random() * 10)
          const docSnapshot = await getDoc(docRef)
          const docSnapshotData = docSnapshot.data()
          const newDocRef = doc(pages)

          const duplicateSlug = `${docSnapshot.data().slug}-${timestamp}${randomNumber}`

          const data = {
            ...docSnapshotData,
            id: newDocRef.id,
            slug: duplicateSlug,
            // Set all timestamp properties.
            createdAt: timestamp,
            updatedAt: timestamp,
            deletedAt: null,
            // Set published false to avoid publishing the duplicate
            published: false,
          }

          await setDoc(newDocRef, data)

          const translationsCollection = collection(pages, id, 'translations')
          const translations = await getDocs(translationsCollection)

          if (!translations.empty) {
            const newTranslationsCollection = collection(pages, newDocRef.id, 'translations')
            const batch = writeBatch(db)

            // eslint-disable-next-line
            for (const document of translations.docs) {
              const translationsDocRef = doc(newTranslationsCollection, document.id)

              batch.set(translationsDocRef, document.data())
            }

            batch.commit().then(() => resolve(true))
          }

          store.dispatch('revisions/create', {
            event: RevisionEventType.DUPLICATE_PAGES,
            id: newDocRef.id,
            newValue: data,
            previousValue: {},
          })

          router.push({
            name: 'pages-page-settings',
            params: { id: newDocRef.id },
          })

          store.dispatch('notify', {
            title: i18n.t('Great!'),
            body: i18n.t('A new {title} has been duplicated 🚀', { title: i18n.t('page') }),
          })

          resolve(newDocRef)
        } catch (error) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong duplicating the {title}', { title: i18n.t('page') }),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },

    /**
     * Fetch all pages which correspond to having a specific Form Id
     *
     * @param {Object}
     * @param {string} id Id of the form
     *
     * @returns {Promise}
     */
    fetchAllByFormId({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { pages } = tenantCtx()

        const q = query(pages, where('formId', '==', id), orderBy('createdAt', 'desc'))

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST_RELATED', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => reject(error))
      })
    },
  },
}

/**
 * Handles the revision for a page Update.
 * It merges all the changes from the payload with the the state of the selected page.
 * Different then with other revisions the recipes are deleted from the previousValue variable because of file size constrains
 *
 * @param {Object} state
 * @param {Object} payload
 * @param {String} id
 *
 * @returns {Promise}
 */
const handlePageUpdateRevision = (state, payload, id) => {
  store.dispatch('revisions/create', {
    event: RevisionEventType.UPDATE_PAGES,
    id,
    newValue: {
      ...payload,
    },
    previousValue: { ...state.current.data, recipes: null },
  })
}
