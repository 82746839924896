/*
   eslint-disable
   import/extensions,
   import/no-unresolved,
   import/order,
   object-curly-newline
*/
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import { getAuth0Instance as authInstance } from '@/plugins/auth0'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
import { getDocs } from 'firebase/firestore'
import i18n from '@/libs/i18n'
import store from '@/store'
import axios from '@axios'
import { allowed, FEATURES } from '@/auth'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getAll: state => state.list,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { oauth } = tenantCtx()

        getDocs(oauth)
          .then(querySnapshot => {
            const data = querySnapshot.docs
              .map(docRef => ({
                id: docRef.id,
                clientID: docRef.id,
                ...docRef.data(),
              }))
              .filter(docRef => docRef.status === 'ACTIVE')

            commit('UPDATE_LIST', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Calls the authorization service to Obtain a new client secret and client id
     *
     * @param {description, tenant_name, tenant_id} payload
     *
     * @returns {Promise<{client_secret, client_id}>}
     */
    create({}, payload) {
      return new Promise(async (resolve, reject) => {
        if (!allowed(FEATURES.CLIENT_ADMIN_API_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached client admin api credentials limit'),
            variant: 'danger',
          })

          return
        }

        const data = {
          ...payload,
        }

        const accessToken = await authInstance().getTokenSilently()

        const config = {
          headers: {
            Authorization: `${jwtDefaultConfig.tokenType} ${accessToken}`,
          },
        }

        axios
          .post(`${process.env.VUE_APP_NOVTI_CLIENT_API_AUTH_SERVER_ENDPOINT}/oauth/generate`, data, config)
          .then(() => {
            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('New credentials has been created 🚀'),
            })

            resolve(data)
          })
      })
    },
    /**
     * Calls the authorization service to revoke client credentials
     *
     * @param {Object} payload
     *
     * @returns {Promise<{void}>}
     */
    revoke({}, payload) {
      return new Promise(async resolve => {
        const data = {
          client_id: payload.clientId,
          tenant_name: payload.tenantName,
        }

        const accessToken = await authInstance().getTokenSilently()

        const config = {
          headers: {
            Authorization: `${jwtDefaultConfig.tokenType} ${accessToken}`,
          },
        }

        axios
          .patch(`${process.env.VUE_APP_NOVTI_CLIENT_API_AUTH_SERVER_ENDPOINT}/oauth/revoke`, data, config)
          .then(() => {
            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('{title} has been revoked 🗑️', { title: i18n.t('Credentials') }),
            })

            resolve(data)
          })
      })
    },
  },
}
