import i18n from '@/libs/i18n'

export default [
  {
    path: '/form-configurations/configurations/list',
    name: 'form-configurations-configurations-list',
    component: () => import('@/views/form-configurations/configurations/configurations-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Form Configurations'),
      action: 'read',
      resource: 'form_configurations',
    },
  },
  {
    path: '/form-configurations/configurations/:id/templates',
    name: 'form-configurations-configurations-templates',
    component: () => import('@/views/form-configurations/configurations/configurations-template/BaseTemplate.vue'),
    meta: {
      pageTitle: i18n.t('Base Templates'),
      action: 'update',
      resource: 'form_configurations',
      breadcrumb: [
        {
          text: i18n.t('Form Configurations'),
          to: '/form-configurations/configurations/list',
        },
        {
          text: '',
          field: 'description',
          active: true,
        },
        {
          text: i18n.t('Templates'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/form-configurations/configurations/create',
    name: 'form-configurations-configurations-create',
    component: () => import('@/views/form-configurations/configurations/configurations-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Create Form Configuration'),
      action: 'create',
      resource: 'form_configurations',
      breadcrumb: [
        {
          text: i18n.t('Form Configurations'),
          to: '/form-configurations/configurations/list',
        },
        {
          text: i18n.t('Create'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/form-configurations/configurations/:id/edit',
    name: 'form-configurations-configurations-edit',
    component: () => import('@/views/form-configurations/configurations/configurations-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Edit Form Configuration'),
      action: 'update',
      resource: 'form_configurations',
      store: {
        subscribe: 'formConfigurations/SET_CURRENT',
        retrieve: 'formConfigurations/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Form Configurations'),
          to: '/form-configurations/configurations/list',
        },
        {
          text: '',
          field: 'description',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },

  // Form Fields
  {
    path: '/form-configurations/form-fields',
    name: 'form-configurations-form-fields-list',
    component: () => import('@/views/form-configurations/form-fields/form-fields-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Form Fields'),
      action: 'read',
      resource: 'form_fields',
    },
  },
  {
    path: '/form-configurations/form-fields/:id/edit',
    name: 'form-configurations-form-fields-edit',
    component: () => import('@/views/form-configurations/form-fields/form-fields-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Form Fields'),
      action: 'update',
      resource: 'form_fields',
      store: {
        subscribe: 'formFields/SET_CURRENT',
        retrieve: 'formFields/getCurrentData',
      },
      breadcrumb: [
        {
          text: i18n.t('Form Fields'),
          to: '/form-configurations/form-fields',
        },
        {
          text: '',
          field: 'label',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },

  // Regular Expressions
  {
    path: '/form-configurations/regular-expressions',
    name: 'form-configurations-regular-expressions-list',
    component: () => import('@/views/form-configurations/regular-expressions/regular-expressions-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Regular Expressions'),
      action: 'read',
      resource: 'regular_expressions',
    },
  },
  {
    path: '/form-configurations/regular-expressions/:id/edit',
    name: 'form-configurations-regular-expressions-edit',
    component: () => import('@/views/form-configurations/regular-expressions/regular-expressions-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Regular Expressions'),
      action: 'update',
      resource: 'regular_expressions',
      store: {
        subscribe: 'regularExpressions/SET_CURRENT',
        retrieve: 'regularExpressions/getCurrentData',
      },
      breadcrumb: [
        {
          text: i18n.t('Regular Expressions'),
          to: '/form-configurations/regular-expressions',
        },
        {
          text: '',
          field: 'description',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
]
