import { getAuth0Instance } from '@/plugins/auth0'

// eslint-disable-next-line import/prefer-default-export
export const authGuard = (to, _, next) => {
  const authService = getAuth0Instance()

  const fn = () => {
    if (authService.isAuthenticated) {
      return next()
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
