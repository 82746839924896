import Vue from 'vue'
import {
  query,
  where,
  limit,
  orderBy,
  getDocs,
  collection,
} from 'firebase/firestore'
import { ref as storageRef } from 'firebase/storage'

let instance

/**
 * Gets the active subscription of the specific tenant
 *
 * @param {Object} tenants The collection reference for the tenant collection
 * @param {Object} tenantRef The document reference for the tenant specific data document
 *
 * @returns {Object} The subscription the subscription for the specific tenant
 */
async function tenantSubscription(tenants, tenantRef) {
  const querySnapshotSubscriptions = await getDocs(
    query(
      collection(tenants, `${tenantRef.id}/subscriptions`),
      orderBy('createdAt', 'desc'),
      limit(1),
    ),
  )

  const [subscriptionDoc] = querySnapshotSubscriptions.docs

  return subscriptionDoc.data()
}

/**
 * Gets the feature package for the specific tenant
 *
 * @param {Object} tenants The collection reference for the tenant collection
 * @param {Object} tenantRef The document reference for the tenant specific data document
 * @param {Object} packages The collection reference for the packages collection
 *
 * @returns {Object} the tenant's subscription package features
 */
async function getPackageFeatures(tenants, tenantRef, packages) {
  const subscription = await tenantSubscription(tenants, tenantRef)

  const querySnapshotPackages = await getDocs(
    query(
      packages,
      where('STRIPE_PRODUCT_ID', '==', subscription.product),
      limit(1),
    ),
  )

  const [packageDoc] = querySnapshotPackages.docs

  return packageDoc.data()
}

export const getTenantContextInstance = () => instance

export const boot = ({ ...options }) => {
  if (instance) return instance

  const {
    db,
    tenant,
    storage,
    themesDb,
    clientThemesRootStorage,
  } = options

  // Cloud Storage shared references
  const templatesStorage = storageRef(storage, 'templates')
  const sharedThemesStorage = storageRef(clientThemesRootStorage, 'themes')

  // Firestore root collections
  const admin = collection(db, 'admin')
  const tenants = collection(db, 'tenants')
  const domains = collection(db, 'domains')
  const packages = collection(db, 'packages')
  const campaigns = collection(db, 'counters')
  const customers = collection(db, 'customers')
  const preferences = collection(db, 'preferences')
  const dnsAuthorizations = collection(db, 'dnsAuthorizations')

  // Firestore themes collections
  const sharedThemes = collection(themesDb, 'themes')

  // Firestore shared collections
  const sharedHelpers = collection(admin, '_shared/helpers')
  const sharedTemplates = collection(admin, '_shared/templates')
  const sharedConnectors = collection(admin, '_shared/connectors')
  const sharedFormFields = collection(admin, '_shared/formFields')
  const sharedRegularExpressions = collection(admin, '_shared/regularExpressions')
  const sharedFormConfigurations = collection(admin, '_shared/formConfigurations')

  instance = new Vue({
    data() {
      return {
        loading: true,
        error: null,

        // Firestore root collections
        admin,
        tenants,
        domains,
        campaigns,
        customers,
        preferences,
        dnsAuthorizations,
        sharedThemes,

        // Shared
        sharedHelpers,
        sharedTemplates,
        sharedConnectors,
        sharedFormFields,
        sharedRegularExpressions,
        sharedFormConfigurations,

        // Cloud Storage shared references
        templatesStorage,
        sharedThemesStorage,

        forms: null,
        pages: null,
        users: null,
        usage: null,
        privacy: null,
        security: null,
        webhooks: null,
        revisions: null,
        formFields: null,
        elements: null,
        developers: null,
        themes: null,
        organization: null,
        integrations: null,
        transactions: null,
        domainMappings: null,
        connectorSettings: null,
        formConfigurations: null,
        regularExpressions: null,

        // Tenant-specific package features
        packageFeatures: null,

        // Cloud Storage tenant-specific references
        pagesStorage: null,
        formsStorage: null,
        uploadsStorage: null,
        elementsStorage: null,
        themesStorage: null,
        preferenceCentersStorage: null,

        // Tenant-specific assets path
        assetsPath: null,
      }
    },
    async created() {
      try {
        const q = query(tenants, where('tenant', '==', tenant), limit(1))

        getDocs(q)
          .then(async querySnapshot => {
            const [tenantRef] = querySnapshot.docs

            // Set the Firestore tenant-specific references
            this.logs = collection(admin, `${tenant}/logs`)
            this.forms = collection(admin, `${tenant}/forms`)
            this.pages = collection(admin, `${tenant}/pages`)
            this.oauth = collection(admin, `${tenant}/oauth`)
            this.users = collection(admin, `${tenant}/users`)
            this.usage = collection(admin, `${tenant}/usage`)
            this.privacy = collection(admin, `${tenant}/privacy`)
            this.security = collection(admin, `${tenant}/security`)
            this.webhooks = collection(admin, `${tenant}/webhooks`)
            this.counters = collection(admin, `${tenant}/counters`)
            this.revisions = collection(admin, `${tenant}/revisions`)
            this.elements = collection(admin, `${tenant}/elements`)
            this.formFields = collection(admin, `${tenant}/formFields`)
            this.developers = collection(admin, `${tenant}/developers`)
            this.themes = collection(admin, `${tenant}/themes`)
            this.campaigns = collection(campaigns, `${tenant}/campaigns`)
            this.organization = collection(admin, `${tenant}/organization`)
            this.integrations = collection(admin, `${tenant}/integrations`)
            this.transactions = collection(customers, `${tenant}/transactions`)
            this.domainMappings = collection(domains, `${tenant}/domainMappings`)
            this.connectorSettings = collection(admin, `${tenant}/connectorSettings`)
            this.preferenceCenters = collection(admin, `${tenant}/preferenceCenters`)
            this.formConfigurations = collection(admin, `${tenant}/formConfigurations`)
            this.regularExpressions = collection(admin, `${tenant}/regularExpressions`)

            // Cloud Storage tenant-specific references
            this.pagesStorage = storageRef(storage, `tenants/${tenant}/pages`)
            this.formsStorage = storageRef(storage, `tenants/${tenant}/forms`)
            this.uploadsStorage = storageRef(storage, `tenants/${tenant}/uploads`)
            this.preferenceCentersStorage = storageRef(storage, `tenants/${tenant}/preference-centers`)
            this.elementsStorage = storageRef(clientThemesRootStorage, `tenants/${tenant}/elements`)
            this.themesStorage = storageRef(clientThemesRootStorage, `tenants/${tenant}/themes`)

            // Tenant-specific package features
            this.packageFeatures = await getPackageFeatures(tenants, tenantRef, packages)

            // Tenant-specific assets path
            this.assetsPath = `/tenants/${tenant}/uploads`

            this.loading = false
          })
      } catch (e) {
        console.error(e)

        this.error = e
      }
    },
  })

  return instance
}

export const TenantContextPlugin = {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$tenantCtx = boot(options)
  },
}
