/* eslint-disable */
import parserPostCSS from 'grapesjs-parser-postcss'
import rteExtension from 'grapesjs-rte-extensions'

export let plugins = {
  plugins: [
    'gjs-preset-webpage',
    parserPostCSS,
    rteExtension
  ]
}
