/**
 * Vue Router 2.x doesn't support multiple guards
 * This feature is supported in Vue 3+ version
 *
 * @see https://github.com/vuejs/vue-router/issues/721
 * @see https://www.npmjs.com/package/vue-router-multiguard
 *
 * I've used the workaround provided in the link above with some minor adjustments
 * Function should be removed after migration to Vue 3.x
 *
 * @author Robert Moelker <@robertmoelker>
 */
const evaluateGuards = (guards, to, from, next) => {
  const guardsLeft = guards.slice(0) // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift()

  if (nextGuard === undefined) {
    next()

    return
  }

  nextGuard(to, from, nextArg => {
    if (nextArg === undefined) {
      evaluateGuards(guardsLeft, to, from, next)

      return
    }

    next(nextArg)
  })
}

const multiGuard = guards => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  return (to, from, next) => evaluateGuards(guards, to, from, next)
}

export default multiGuard
