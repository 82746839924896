import {
  doc, documentId, getDoc, getDocs, query, where,
} from 'firebase/firestore'
import forEachAsync from '@/@core/utils/forEachAsync'
import { getTenantContextInstance as tenantCtx } from '@/plugins'
import { convertCustomFormat } from '@/libs/date-format'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getAll: state => state.list,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    /**
     * fetches all usages
     * fetches first all usages and then gets the prices per day from the usage metadata childCollection
     *
     * @param {Object} commit
     *
     * @returns {Promise}
     */
    fetchAll({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { usage } = tenantCtx()

          const querySnapshot = await getDocs(usage)
          const data = []

          await forEachAsync(querySnapshot.docs, async document => {
            const pricesRef = doc(usage, `${document.id}/metadata/prices`)
            const pricesDoc = await getDoc(pricesRef)

            if (!pricesDoc.exists()) return

            const prices = pricesDoc.data()

            data.push({
              date: convertCustomFormat(document.id, 'DDMMYYYY', 'YYYY-MM-DD'),
              prices,
              ...document.data(),
            })
          })

          commit('UPDATE_LIST', data)
          resolve(data)
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    },

    /**
     * Fetches monthly usage and then gets the prices per day from the usage metadata childCollection
     *
     * @param {Object} commit
     *
     * @returns {Promise}
     */
    fetchByMonth({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { usage } = tenantCtx()

          const [currentDay, currentMonth, currentYear] = new Date().toLocaleString('nl', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).split('-')

          const dates = []

          for (let i = 1; i < currentDay; i += 1) {
            dates.push(`${String(i).padStart(2, '0')}${currentMonth}${currentYear}`)
          }

          const q = query(usage, where(documentId(), 'in', dates))
          const querySnapshot = await getDocs(q)
          const data = []

          await forEachAsync(querySnapshot.docs, async document => {
            const pricesRef = doc(usage, `${document.id}/metadata/prices`)
            const pricesDoc = await getDoc(pricesRef)

            if (!pricesDoc.exists()) return

            const prices = pricesDoc.data()

            data.push({
              date: convertCustomFormat(document.id, 'DDMMYYYY', 'YYYY-MM-DD'),
              prices,
              ...document.data(),
            })
          })

          commit('UPDATE_LIST', data)
          resolve(data)
        } catch (error) {
          reject(error)
        }
      })
    },
  },
}
