import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
} from 'firebase/firestore'
import { constructQuery } from '@core/utils/utils'
import { uniqBy } from 'lodash'
import { RevisionEventType } from '@core/revisions/revisions'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import { allowed, FEATURES } from '@/auth'

export default {
  namespaced: true,
  state: {
    list: [],
    filteredList: [],
    shared: [],
    current: {},
    currentData: {},
  },
  getters: {
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })).filter(el => !el.deletedAt),
    getFilteredList: state => state.filteredList.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getShared: state => state.shared.map(docRef => ({ ...docRef.data(), id: docRef.id })).filter(el => !el.deletedAt),
    getCurrent: state => state.current,
    getCurrentData: state => state.currentData,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      const { docs, pagination } = payload

      state.list = (!pagination) ? docs : uniqBy([...state.list, ...docs], 'id')
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.filteredList = payload
    },
    UPDATE_SHARED(state, payload) {
      state.shared = payload
    },

    SET_CURRENT(state, payload) {
      state.current = payload
      state.currentData = ({ ...payload.data(), id: payload.id })
    },
  },
  actions: {
    /**
     * Fetches all.
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        const { regularExpressions } = tenantCtx()
        const { queryOptions = {}, pagination = false } = payload

        const q = constructQuery(regularExpressions, 'description', queryOptions)

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', { docs: querySnapshot.docs, pagination })
            resolve(querySnapshot.docs)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches all system level regexes.
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchShared({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { sharedRegularExpressions } = tenantCtx()

        const q = constructQuery(sharedRegularExpressions, 'description', queryOptions)

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_SHARED', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches a document by the given ID.
     *
     * @param {Object} id The page ID.
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { regularExpressions } = tenantCtx()
        const docRef = doc(regularExpressions, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            if (docSnapshot.data().deletedAt) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the Regular Expression'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID.
     *
     * @param {string} id The current page ID
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { regularExpressions, sharedRegularExpressions } = tenantCtx()
        const qRegularExpressions = constructQuery(regularExpressions, 'createdAt', {
          ...queryOptions,
        })
        const qShared = constructQuery(sharedRegularExpressions, 'system', {
          ...queryOptions,
        })

        Promise.all([getDocs(qRegularExpressions), getDocs(qShared)])
          .then(([querySnapshotRegularExpressions, querySnapshotShared]) => {
            const result = [...querySnapshotRegularExpressions.docs, ...querySnapshotShared.docs]

            commit('UPDATE_FILTERED_LIST', result)
            resolve(result)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('regular expressions') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * resets the filter list
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    resetFiltered({ commit }) {
      return new Promise(resolve => {
        commit('UPDATE_FILTERED_LIST', [])
        resolve()
      })
    },

    /**
     * Updates a document by the given payload.
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    update({ state }, { value, previousValue }) {
      return new Promise((resolve, reject) => {
        updateDoc(state.current.ref, value)
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_REGULAR_EXPRESSIONS,
              id: value.id,
              newValue: value,
              previousValue,
            })
            store.dispatch('notify', { body: i18n.t('Regular Expression has been updated') })
            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the Form Configuration'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Creates a document by the given payload.
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.REGULAR_EXPRESSIONS_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your {title} limit', { title: i18n.t('regular expressions') }),
            variant: 'danger',
          })

          return
        }

        const { regularExpressions } = tenantCtx()

        const docRef = doc(regularExpressions)

        setDoc(docRef, { ...payload, deletedAt: null, id: docRef.id })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_REGULAR_EXPRESSIONS,
              id: docRef.id,
              newValue: payload,
              previousValue: {},
            })

            store.dispatch('notify', { title: i18n.t('Great!'), body: i18n.t('A new {title} has been created 🚀', { title: i18n.t('regular expression') }) })
            resolve(docRef)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the Regular Expression'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Soft deletes a document.
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    softDelete({}, payload) {
      return new Promise((resolve, reject) => {
        const { regularExpressions } = tenantCtx()
        const docRef = doc(regularExpressions, payload.id)

        updateDoc(docRef, {
          deletedAt: payload.deletedAt,
        })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_REGULAR_EXPRESSIONS,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
