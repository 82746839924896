import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    editors: {},
  },
  getters: {
    get: state => identifier => {
      if (!state.editors[identifier]) {
        return false
      }

      return state.editors[identifier]
    },
    getSetting: state => (identifier, key) => {
      if (!state.editors[identifier]) {
        return false
      }

      return state.editors[identifier][key] ?? false
    },
  },
  mutations: {
    UPDATE(state, payload) {
      const { id, show, format } = payload

      if (!id) {
        console.error('Unable to open code editor due to missing identifier')

        return
      }

      if (!state.editors[id]) {
        Vue.set(state.editors, id, {})
      }

      if (Object.prototype.hasOwnProperty.call(payload, 'show')) {
        Vue.set(state.editors[id], 'show', show ?? false)
      }

      if (Object.prototype.hasOwnProperty.call(payload, 'format')) {
        Vue.set(state.editors[id], 'format', format ?? false)
      }
    },
  },
  actions: {},
}
