import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]

      messages[locale] = locales(key)
    }
  })

  return messages
}

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  'nl-NL': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  'en-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
    },
  },
}

/**
 * Returns an array of locales to be used for the selection of the application language.
 *
 * @returns {Array}
 */
export const locales = [
  {
    value: 'en-US',
    text: 'English (United States)',
  },
  {
    value: 'es',
    text: 'Spanish',
  },
]

export default new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: loadLocaleMessages(),
  numberFormats,
})
