import i18n from '@/libs/i18n'

export default [
  {
    path: '/logs/list',
    name: 'logs-list',
    component: () => import('@/views/logs/logs-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Logs'),
      action: 'read',
      resource: 'logs',
    },
  },
  {
    path: '/logs/:id/view',
    name: 'logs-view',
    component: () => import('@/views/logs/logs-view/View.vue'),
    meta: {
      pageTitle: i18n.t('View Log'),
      action: 'read',
      resource: 'logs',
      breadcrumb: [
        {
          text: i18n.t('Logs list'),
          to: '/logs/list',
        },
        {
          text: i18n.t('View'),
          active: true,
        },
      ],
    },
  },
]
