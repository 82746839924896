import i18n from '@/libs/i18n'

export default [
  // *===============================================---*
  // *--------- Support --------------------------------*
  // *===============================================---*
  {
    path: '/support/request',
    name: 'support-request',
    component: () => import('@/views/support/request/Request.vue'),
    meta: {
      pageTitle: i18n.t('Request'),
    },
  },
  {
    path: '/support/docs',
    name: 'documentation',
    component: () => import('@/views/support/documentation/Documentation.vue'),
    meta: {
      pageTitle: i18n.t('Documentation'),
    },
  },
]
