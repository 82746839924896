/**
 * Executes the provided callback once for each element.
 * Callbacks are run concurrently,
 * and are only invoked for properties of the array that have been initialized (including those initialized with *undefined*), for unassigned ones `callback` is not run.
 *
 * @param {Array} array - Array to iterate over.
 * @param {Function} callback - Function to apply each item in `array`. Accepts three arguments: `currentValue`, `index` and `array`.
 * @param {Object} [thisArg] - Value to use as *this* when executing the `callback`.
 *
 * @return {Promise} - Returns a Promise with undefined value.
 */
export default async function forEachAsync(array, callback, thisArg) {
  const promiseArray = []

  for (let i = 0; i < array.length; i += 1) {
    if (i in array) {
      const p = Promise.resolve(array[i]).then(currentValue => callback.call(thisArg || this, currentValue, i, array))

      promiseArray.push(p)
    }
  }

  await Promise.all(promiseArray)
}
