import i18n from '@/libs/i18n'

export default [
  {
    path: '/themes',
    name: 'themes',
    component: () => import('@/views/themes/themes/Themes.vue'),
    meta: {
      pageTitle: i18n.t('Themes'),
      action: 'read',
      resource: 'themes',
    },
  },
  {
    path: '/themes/new',
    name: 'themes-new',
    component: () => import('@/views/themes/themes/NewTheme.vue'),
    meta: {
      pageTitle: i18n.t('New Theme'),
      action: 'create',
      resource: 'themes',
      breadcrumb: [
        {
          text: i18n.t('Themes'),
          to: '/themes',
        },
        {
          text: '',
          field: 'title',
          active: true,
        },
        {
          text: i18n.t('New'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/themes/:id/edit',
    name: 'themes-edit',
    component: () => import('@/views/themes/themes/EditTheme.vue'),
    meta: {
      pageTitle: i18n.t('Edit Theme'),
      action: 'update',
      resource: 'themes',
      breadcrumb: [
        {
          text: i18n.t('Themes'),
          to: '/themes',
        },
        {
          text: '',
          field: 'title',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/themes/elements',
    name: 'themes-elements',
    component: () => import('@/views/themes/elements/Elements.vue'),
    meta: {
      pageTitle: i18n.t('Elements'),
      action: 'read',
      resource: 'themes',
    },
  },
]
