/* eslint-disable no-underscore-dangle */
import { getUnix, getFutureUnix } from '@/libs/date-format'

/**
 * Revisions class with the mandatory attributes
 */
export class Revision {
  /**
   * @param {Object} event
   * @param {string} id
   * @param {Object} user
   * @param {Object} previousValue
   * @param {Object} newValue
   */
  constructor(event, id, user, previousValue = {}, newValue = {}, userEmail = '') {
    this.event = event
    this.user = user
    this.userEmail = userEmail
    this.referenceId = id
    this.previousValue = previousValue
    this.newValue = newValue
    this.createdAt = getUnix()
    this._TTL = getFutureUnix(1, 'M')
  }
}

export const RevisionType = {
  PAGES: 'PAGE',
  FORM_CONFIGURATIONS: 'FORM_CONFIGURATIONS',
  FORM_FIELDS: 'FORM_FIELD',
  REGULAR_EXPRESSIONS: 'REGULAR_EXPRESSIONS',
  FORMS: 'FORMS',
  COUNTERS: 'COUNTER',
  PREFERENCE_CENTERS: 'PREFERENCE_CENTERS',
  PRIVACY: 'PRIVACY',
  SECURITY: 'SECURITY',
  USERS: 'USER',
  ORGANIZATION: 'ORGANIZATION',
  CONNECTOR_SETTINGS: 'CONNECTOR_SETTINGS',
  DOMAIN_MAPPINGS: 'DOMAIN_MAPPINGS',
  WEBHOOKS: 'WEBHOOKS',
  THEMES: 'THEMES',
  ELEMENTS: 'ELEMENTS',
}

export const RevisionEventType = {
  // PAGES
  UPDATE_PAGES: 'UPDATE_PAGES',
  DELETE_PAGES: 'DELETE_PAGES',
  DUPLICATE_PAGES: 'DUPLICATE_PAGES',
  CREATE_PAGES: 'CREATE_PAGES',

  // FORM CONFIGURATIONS
  UPDATE_FORM_CONFIGURATIONS: 'UPDATE_FORM_CONFIGURATIONS',
  DELETE_FORM_CONFIGURATIONS: 'DELETE_FORM_CONFIGURATIONS',
  DUPLICATE_FORM_CONFIGURATIONS: 'DUPLICATE_FORM_CONFIGURATIONS',
  CREATE_FORM_CONFIGURATIONS: 'CREATE_FORM_CONFIGURATIONS',

  // FORM CONFIGURATIONS / Forms fields
  UPDATE_FORM_FIELDS: 'UPDATE_FORM_FIELDS',
  DELETE_FORM_FIELDS: 'DELETE_FORM_FIELDS',
  CREATE_FORM_FIELDS: 'CREATE_FORM_FIELDS',

  // FORM CONFIGURATIONS / Regular Expressions
  UPDATE_REGULAR_EXPRESSIONS: 'UPDATE_REGULAR_EXPRESSIONS',
  DELETE_REGULAR_EXPRESSIONS: 'DELETE_REGULAR_EXPRESSIONS',
  CREATE_REGULAR_EXPRESSIONS: 'CREATE_REGULAR_EXPRESSIONS',

  // FORMS
  UPDATE_FORMS: 'UPDATE_FORMS',
  DUPLICATE_FORMS: 'DUPLICATE_FORMS',
  DELETE_FORMS: 'DELETE_FORMS',
  CREATE_FORMS: 'CREATE_FORMS',

  // COUNTERS
  UPDATE_COUNTERS: 'UPDATE_COUNTERS',
  DELETE_COUNTERS: 'DELETE_COUNTERS',
  CREATE_COUNTERS: 'CREATE_COUNTERS',

  // PREFERENCE CENTERS
  UPDATE_PREFERENCE_CENTERS: 'UPDATE_PREFERENCE_CENTERS',
  DELETE_PREFERENCE_CENTERS: 'DELETE_PREFERENCE_CENTERS',
  CREATE_PREFERENCE_CENTERS: 'CREATE_PREFERENCE_CENTERS',

  // PRIVACY
  UPDATE_PRIVACY: 'UPDATE_PRIVACY',

  // SECURITY
  UPDATE_SECURITY: 'UPDATE_SECURITY',

  // USERS
  UPDATE_USERS: 'UPDATE_USERS',
  DELETE_USERS: 'DELETE_USERS',
  INVITE_USERS: 'INVITE_USERS',

  // GENERAL SETTINGS / Organization
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',

  // GENERAL SETTINGS / Connector Settings
  UPDATE_CONNECTOR_SETTINGS: 'UPDATE_CONNECTOR_SETTINGS',
  DELETE_CONNECTOR_SETTINGS: 'DELETE_CONNECTOR_SETTINGS',

  // GENERAL SETTINGS / Domain Mappings
  UPDATE_DOMAIN_MAPPINGS: 'UPDATE_DOMAIN_MAPPINGS',
  DELETE_DOMAIN_MAPPINGS: 'DELETE_DOMAIN_MAPPINGS',

  // GENERAL SETTINGS / Webhooks
  UPDATE_WEBHOOKS: 'UPDATE_WEBHOOKS',
  DELETE_WEBHOOKS: 'DELETE_WEBHOOKS',
  CREATE_WEBHOOKS: 'CREATE_WEBHOOKS',

  // Themes / Themes
  UPDATE_THEMES: 'UPDATE_THEMES',
  DELETE_THEMES: 'DELETE_THEMES',
  CREATE_THEMES: 'CREATE_THEMES',

  // Themes / Themes
  UPDATE_ELEMENTS: 'UPDATE_ELEMENTS',
  DELETE_ELEMENTS: 'DELETE_ELEMENTS',
  CREATE_ELEMENTS: 'CREATE_ELEMENTS',
}

/**
 * Returns the color based on the eventType
 *
 * create = green "success"
 * update = blue "info"
 * delete = red "warning"
 *
 * @param {string} eventType
 *
 * @returns string
 */
export const getEventColor = eventType => {
  const createString = 'CREATE'
  const updateString = 'UPDATE'
  const duplicateString = 'DUPLICATE'
  const deleteString = 'DELETE'
  const inviteString = 'INVITE'

  switch (true) {
    case eventType.includes(createString):
    case eventType.includes(inviteString):
      return 'success'
    case eventType.includes(updateString):
      return 'info'
    case eventType.includes(duplicateString):
      return 'primary'
    case eventType.includes(deleteString):
      return 'warning'
    default:
      return 'secondary'
  }
}

/**
 * Transforms the event message based on the eventType
 *
 * @param {string} eventType - CREATE_WEBHOOK
 *
 * @example
 * // returns 'created webhook'
 * getEventMessage(CREATE_WEBHOOK)
 *
 * @example
 * // returns 'updated form configuration'
 * getEventMessage(UPDATE_FORM_CONFIGURATION)
 *
 * @returns {string} Returns the event message based on the eventType
 */
export const getEventMessage = eventType => {
  const lowerCaseMessage = eventType.toLowerCase().replace(/_/g, ' ').split(' ')

  return `${lowerCaseMessage[0]}d ${lowerCaseMessage.slice(1).join(' ')}`
}

/**
 * Returns the icon based on the eventType
 *
 * @param {string} eventType
 *
 * @returns string
 */
export const getEventIcon = eventType => {
  switch (true) {
    case eventType.includes(RevisionType.PAGES):
    case eventType.includes(RevisionType.THEMES):
    case eventType.includes(RevisionType.ELEMENTS):
      return 'LayoutIcon'
    case eventType.includes(RevisionType.FORM_CONFIGURATIONS):
    case eventType.includes(RevisionType.FORM_FIELDS):
    case eventType.includes(RevisionType.REGULAR_EXPRESSIONS):
      return 'SlidersIcon'
    case eventType.includes(RevisionType.FORMS):
      return 'FileIcon'
    case eventType.includes(RevisionType.COUNTERS):
      return 'PercentIcon'
    case eventType.includes(RevisionType.PREFERENCE_CENTERS):
      return 'ToggleRightIcon'
    case eventType.includes(RevisionType.USERS):
      return 'UserIcon'
    case eventType.includes(RevisionType.ORGANIZATION):
    case eventType.includes(RevisionType.CONNECTOR_SETTINGS):
    case eventType.includes(RevisionType.DOMAIN_MAPPINGS):
      return 'SettingsIcon'
    default:
      return 'SettingsIcon'
  }
}

/**
 * Returns the eventName based on the revision
 *
 * @param {Object} revision
 *
 * @returns string
 */
export const getEventName = revision => {
  switch (true) {
    case revision.event.includes(RevisionType.PAGES):
    case revision.event.includes(RevisionType.COUNTERS):
    case revision.event.includes(RevisionType.THEMES):
    case revision.event.includes(RevisionType.ELEMENTS):
      return revision.newValue.name || revision.previousValue.name
    case revision.event.includes(RevisionType.FORM_CONFIGURATIONS):
    case revision.event.includes(RevisionType.REGULAR_EXPRESSIONS):
    case revision.event.includes(RevisionType.FORMS):
    case revision.event.includes(RevisionType.WEBHOOKS):
      return revision.newValue.description || revision.previousValue.description
    case revision.event.includes(RevisionType.USERS):
      return revision.newValue.firstName || revision.newValue.email
    case revision.event.includes(RevisionType.PREFERENCE_CENTERS):
      return revision.newValue.title
    case revision.event.includes(RevisionType.FORM_FIELDS):
      return revision.newValue.label
    case revision.event.includes(RevisionType.ORGANIZATION):
      return revision.newValue.name
    case revision.event.includes(RevisionType.CONNECTOR_SETTINGS):
    case revision.event.includes(RevisionType.DOMAIN_MAPPINGS):
      return ''
    default:
      return ''
  }
}
