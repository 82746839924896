import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  where,
} from 'firebase/firestore'
import { constructQuery } from '@core/utils/utils'
import { uniqBy } from 'lodash'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import { getUnix } from '@/libs/date-format'

export default {
  namespaced: true,
  state: {
    list: [],
    filteredList: [],
    params: {},
    removeModal: {
      show: false,
      data: {},
    },
    current: {},
    currentData: {},
  },
  getters: {
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getById: state => id => state.list.find(el => el.id === id),
    getCurrent: state => state.current,
    getCurrentData: state => state.currentData,
    getFilteredList: state => state.filteredList.map(docRef => ({ ...docRef.data(), id: docRef.id })),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = uniqBy([...state.list, ...payload], 'id')
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.filteredList = payload
    },
    FILTER_PARAMS(state, payload) {
      state.params = payload
    },
    SET_CURRENT(state, payload) {
      state.current = payload
      state.currentData = ({ ...payload.data(), id: payload.id })
    },
  },
  actions: {
    /**
     * Fetches all
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, {
      max, levels, from, to, order, offset,
    }) {
      return new Promise((resolve, reject) => {
        const { logs } = tenantCtx()

        let q = constructQuery(logs, 'createdAt', {
          max, order, offset,
        })

        if (from) {
          q = constructQuery(logs, 'createdAt', {
            max,
            order,
            offset,
            where: [
              where('level', 'in', levels),
              where('createdAt', '>=', getUnix(from)),
              where('createdAt', '<=', (to ? getUnix(to) : getUnix())),
            ],
          })
        }

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the logs'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { logs } = tenantCtx()

        let q = constructQuery(logs, 'createdAt', {
          ...queryOptions,
        })

        if (queryOptions.from || queryOptions.to) {
          // ToDate should always be available / set
          const whereClauses = [
            where('createdAt', '<=', (queryOptions.to ? getUnix(queryOptions.to) : getUnix())),
          ]

          if (queryOptions.from) {
            whereClauses.push(
              where('createdAt', '>=', getUnix(queryOptions.from)),
            )
          }

          // Check if there are any where clauses from search queryOptions, Then add to other whereClauses.
          if (queryOptions.where) {
            whereClauses.push(...queryOptions.where)
          }

          q = constructQuery(logs, 'createdAt', {
            ...queryOptions,
            where: whereClauses,
          })
        }

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_FILTERED_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the logs'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * resets the filter list
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    resetFiltered({ commit }) {
      return new Promise(resolve => {
        commit('UPDATE_FILTERED_LIST', [])
        resolve()
      })
    },

    /**
     * Fetches a document by the given ID
     *
     * @param {string} id The logs document ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { logs } = tenantCtx()
        const docRef = doc(logs, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the logs'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID
     *
     * @param {string} id
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    update({ state }, payload) {
      return new Promise((resolve, reject) => {
        updateDoc(state.current.ref, payload)
          .then(() => {
            store.dispatch('notify', { body: i18n.t('Log has been updated') })
            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the Log'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },
  },
}
