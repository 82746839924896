import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

const filestackConfig = () => ({
  key: process.env.VUE_APP_FILESTACK_API_KEY,
  btnText: i18n.t('+ Upload Image'),
  filestackOpts: {
    maxFiles: 1,
    maxSize: 512000,
    accept: 'image/*',
    storeTo: {
      path: tenantCtx().assetsPath,
    },
  },
})

export default filestackConfig
