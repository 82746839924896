/*
  eslint-disable
  import/extensions,
  import/no-unresolved
*/

import i18n from '@/libs/i18n'

export default [
  {
    path: '/forms/list',
    name: 'forms-list',
    component: () => import('@/views/forms/forms/forms-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Forms'),
      action: 'read',
      resource: 'forms',
    },
  },
  {
    path: '/forms/create',
    name: 'forms-create',
    component: () => import('@/views/forms/forms/forms-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Create Form'),
      action: 'create',
      resource: 'forms',
      breadcrumb: [
        {
          text: i18n.t('Forms'),
          to: '/forms/list',
        },
        {
          text: i18n.t('Create'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/forms/:id/edit',
    name: 'forms-edit',
    component: () => import('@/views/forms/forms/forms-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Edit Form'),
      action: 'update',
      resource: 'forms',
      store: {
        subscribe: 'forms/SET_CURRENT',
        retrieve: 'forms/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Forms'),
          to: '/forms/list',
        },
        {
          text: '',
          field: 'description',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
  // Transactions Crud
  {
    path: '/forms/transactions/list',
    name: 'forms-transactions-list',
    component: () => import('@/views/forms/transactions/transactions-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Transactions'),
      action: 'read',
      resource: 'transactions',
    },
  },
  {
    path: '/forms/transactions/:id/view',
    name: 'transactions-edit',
    component: () => import('@/views/forms/transactions/transactions-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('View Transaction'),
      action: 'read',
      resource: 'transactions',
      breadcrumb: [
        {
          text: i18n.t('Transactions'),
          to: '/forms/transactions/list',
        },
        {
          text: i18n.t('View'),
          active: true,
        },
      ],
    },
  },
]
