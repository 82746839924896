import {
  ref,
  listAll,
  getDownloadURL,
} from 'firebase/storage'
import axios from '@axios'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getAll: state => state.list,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    /**
     * Fetches all
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { templatesStorage } = tenantCtx()
        const listRef = ref(templatesStorage)
        const list = []

        // Find all the prefixes and items.
        listAll(listRef)
          .then(res => {
            res.prefixes.forEach(folderRef => {
              getDownloadURL(ref(folderRef, 'metadata.json'))
                .then(metadataUrl => {
                  axios.get(metadataUrl)
                    .then(content => {
                      list.push({
                        ...content.data,
                        id: folderRef.name,
                        thumbnail: `${process.env.VUE_APP_CDN_URL}/${folderRef.fullPath}/thumbnail.png`,
                      })
                    })
                })
            })

            commit('UPDATE_LIST', list)
            resolve(list)
          }).catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the templates'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Downloads a template file content by the template ID
     *
     * @param {string} id The template ID
     *
     * @returns {object}
     */
    downloadFileContent({}, payload) {
      const { templatesStorage } = tenantCtx()

      return new Promise((resolve, reject) => {
        getDownloadURL(ref(templatesStorage, `${payload.id}/${payload.file}`))
          .then(metadataUrl => {
            axios.get(metadataUrl)
              .then(content => {
                resolve(content.data)
              })
          })
          .catch(error => reject(error))
      })
    },
  },
}
