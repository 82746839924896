/*
   eslint-disable
   import/extensions,
   import/no-unresolved,
   import/prefer-default-export,
*/
import {
  ref,
  uploadBytes,
} from 'firebase/storage'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

/**
 * Bootstraps the Storage Manager
 *
 * @param {*} editor
 * @param {*} insertedPage
 */
export function bootstrapStorageManager(editor, insertedPage) {
  const { pagesStorage } = tenantCtx()

  // Get the current page data
  let currentPage = insertedPage || store.getters['pages/getCurrent']

  // Defile all file paths
  const cssPath = `${currentPage.snapshot.id}/styles.css`
  const htmlPath = `${currentPage.snapshot.id}/index.html`
  const stylesPath = `${currentPage.snapshot.id}/styles.json`
  const translationsPath = `${currentPage.snapshot.id}/translations/`

  editor.StorageManager.add('google-firebase', {
    load(keys, clb, clbErr) {
      clb(currentPage.data.recipes)
    },

    /**
     * Stores the data in Google Cloud Storage and Firestore
     *
     * @param  {Object} data Data object to store
     * @param  {Function} clb Callback function to call when the load is ended
     * @param  {Function} clbErr Callback function to call in case of errors
     */
    async store(data, clb, clbErr) {
      const uploads = []

      // Get the current page data if the data has been edited during the opening of the page builder
      // and the execution of the store command
      currentPage = insertedPage || store.getters['pages/getCurrent']

      // Let us get the selected form ID to store it under `formId` as well
      const formId = editor.DomComponents.componentsById.formio?.getAttributes()?.form ?? null
      const counterId = editor.DomComponents.componentsById.counter?.getAttributes()?.counter ?? null
      const {
        'supported-languages': supportedLanguages = null,
        language = null,
        translations = null,
      } = editor.DomComponents.componentsById['content-translator']?.getAttributes() || {}

      if (formId) {
        const form = await store.dispatch('forms/fetchById', formId)

        if (formId && form?.data()?.deletedAt) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong saving the page. Selected form does not exists'),
            variant: 'danger',
          })

          return
        }
      }

      // If a counter is attached to the page, Inject the campaignCode into the page.
      if (counterId) {
        const counter = await store.dispatch('counters/fetchById', counterId)

        if (counter?.data()?.deletedAt) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong saving the page. Selected counter does not exists'),
            variant: 'danger',
          })

          return
        }

        // Only inject the campaignCode if it is filled in the counter.
        if (counter.data().campaignCode) {
          currentPage.data = {
            ...currentPage.data,
            campaignCode: counter.data().campaignCode,
          }
        }
      }

      // Get the CSS from the editor's CSS rules as workaround to avoid missing CSS issue.
      // See https://gitlab.com/growingminds/internal/novti-admin/novti-admin-vuejs/-/issues/111
      const rulesToCSS = editor.Css.getRules().map(rule => rule.toCSS()).join('')

      prepareStorage(rulesToCSS, 'text/css', ref(pagesStorage, cssPath), uploads)
      prepareStorage(data['novti-recipe-styles'], 'application/json', ref(pagesStorage, stylesPath), uploads)
      prepareStorage(data['novti-recipe-html'], 'text/html', ref(pagesStorage, htmlPath), uploads)

      if (translations) {
        Object.keys(translations).forEach(lang => {
          prepareStorage(JSON.stringify(translations[lang]), 'application/json', ref(pagesStorage, `${translationsPath}/${lang}.json`), uploads)
        })
      }

      // Prepare Firestore payload.
      // Doing this we avoid storing `data` as it might contain incomplete CSS.
      const recipes = {
        'novti-recipe-css': rulesToCSS,
        'novti-recipe-html': data['novti-recipe-html'],
        'novti-recipe-styles': data['novti-recipe-styles'],
        'novti-recipe-components': data['novti-recipe-components'],
      }

      await Promise.all(uploads)
        .then(() => {
          store.dispatch('pages/updateCurrent', {
            ...currentPage.data,
            recipes,
            formId,
            counterId,
            language,
            supportedLanguages,
            translations,
          })
          clb()
        })
        .catch(error => console.debug(error))
    },
  })

  /**
   * Prepare files to upload to Cloud Storage
   *
   * @param {Object} data
   * @param {string} type The type of the headers e.g. application/json
   * @param {Object} ref  The reference for the Cloud Storage
   * @param {Array} uploads The list of items to upload
   *
   * @returns {void}
   */
  const prepareStorage = (item, type, reference, uploads) => {
    const data = new Blob([item], { type })

    uploads.push(uploadBytes(reference, data))
  }
}
