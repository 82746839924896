import { doc, setDoc } from "firebase/firestore"
import { getTenantContextInstance as tenantCtx } from "@/plugins/tenant"

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    /**
     * Create a campaign inside the campaigns/{temant}/campaigns collection
     * for usage on the page renderer.
     *
     * @param {*} payload
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        const { campaigns } = tenantCtx()
        const docRef = doc(campaigns, payload.campaignCode)

        setDoc(docRef, { ...payload }, { merge: true })
          .then(() => {
            resolve(docRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
