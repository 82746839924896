import i18n from '@/libs/i18n'
import { authGuard, routeProtection } from '@/auth'
import multiGuardRouter from '@/plugins/multi-guard-router'
import { customerPortal } from '@/plugins/stripe'

export default [
  {
    path: '/usage-and-billing/usage',
    name: 'usage',
    component: () => import('@/views/usage-and-billing/usage/usage-dashboard/Dashboard.vue'),
    meta: {
      pageTitle: i18n.t('Usage'),
      action: 'read',
      resource: 'usage',
    },
  },

  // Billing
  {
    path: '/usage-and-billing/billing',
    name: 'billing',
    meta: {
      action: 'read',
      resource: 'billing',
    },
    beforeEnter: multiGuardRouter([authGuard, routeProtection, customerPortal]),
  },
]
