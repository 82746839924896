import {
  doc, addDoc, getDoc, getDocs, collection, deleteDoc, updateDoc, setDoc,
} from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins'
import store from '@/store'
import i18n from '@/libs/i18n'
import { RevisionEventType } from '@core/revisions/revisions'
import { capitalizeFirstLetter } from '@/libs/string-format'
import { sortBy } from 'lodash'
import forEachAsync from '@/@core/utils/forEachAsync'

export default {
  namespaced: true,
  state: {
    current: {
      csp: null,
      scripts: null,
      styles: null,
    },
  },
  getters: {
    getCurrent: state => state.current,
    getSortedAsset: state => asset => sortBy(state.current[asset], 'order'),
  },
  mutations: {
    UPDATE_CURRENT(state, payload) {
      state.current.csp = payload
    },
    UPDATE_CURRENT_ASSET(state, { asset, location, docs }) {
      state.current[asset] = docs.map(docRef => ({ ...docRef.data(), id: docRef.id, location }))
    },
  },
  actions: {
    /**
     * Fetches the Content Security Policy (CSP) record from the security module and updates the current data.
     *
     * @param {Object} commit - The Vuex commit function for state mutations.
     *
     * @returns {Promise<Object>} A promise that resolves with the fetched CSP data.
     * @throws {Error} If there's an error while fetching the CSP record.
     */
    fetchCSP({ commit }) {
      return new Promise((resolve, reject) => {
        const { security } = tenantCtx()
        const d = doc(security, 'csp')

        getDoc(d)
          .then(documentSnapshot => {
            const data = documentSnapshot.data()

            commit('UPDATE_CURRENT', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Upserts (creates or updates) the Content Security Policy (CSP) record in the security module.
     *
     * @param {Object} context - The Vuex store context object.
     * @param {Object} payload - The data payload representing the CSP to be upserted.
     * @returns {Promise<Object>} A promise that resolves to the upserted CSP data.
     */
    upsertCSP({ state }, payload) {
      return new Promise(resolve => {
        const { security } = tenantCtx()

        const id = 'security'
        const docRef = doc(security, 'csp')

        setDoc(docRef, payload)
          .then(async () => {
            delete payload?.updatedAt
            delete state.current?.updatedAt

            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_SECURITY,
              id,
              newValue: payload,
              previousValue: state.current,
            })

            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('{title} has been updated', { title: i18n.t('Content Security Policy') }),
            })

            await store.dispatch('security/fetchCSP')

            resolve(payload)
          })
      })
    },

    /**
     * Fetches asset records of a specified type and locations from the security module.
     *
     * @param {Object} commit - The Vuex commit function for state mutations.
     * @param {Object} options - An object containing asset and locations information.
     * @param {string} options.asset - The type of asset to fetch ('scripts', 'styles').
     * @param {Array<string>} options.locations - An array of locations to fetch the asset from (e.g., ['head']).
     *
     * @returns {Promise<void>} A promise that resolves when the asset data is successfully fetched and updated.
     * @throws {Error} If there's an error while fetching the asset data.
     */
    fetchAssets({ commit }, { asset, locations }) {
      return new Promise((resolve, reject) => {
        const { security } = tenantCtx()

        locations.forEach(location => {
          getDocs(collection(security, asset, location)).then(querySnapshot => {
            commit('UPDATE_CURRENT_ASSET', {
              asset,
              location,
              docs: querySnapshot.docs,
            })

            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      })
    },

    /**
     * Creates a new asset record within the specified asset type and location in the security module.
     *
     * @param {Object} _ - The Vuex store context object (no direct usage in this function).
     * @param {Object} options - An object containing asset and record information.
     * @param {string} options.asset - The type of asset to which the record belongs ('scripts', 'styles').
     * @param {Object} options.record - The record data to be created.
     *
     * @returns {Promise<DocumentReference>} A promise that resolves with a reference to the newly created record.
     * @throws {Error} If there's an error while creating the record.
     */
    create({}, { asset, record }) {
      return new Promise((resolve, reject) => {
        const { security } = tenantCtx()
        const docRef = collection(security, asset, record.location)

        addDoc(docRef, record)
          .then(newDocRef => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_SECURITY,
              id: `assets_${asset}`,
              newValue: record,
            })

            resolve(newDocRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Updates an existing asset record within the specified asset type and location in the security module.
     *
     * @param {Object} _ - The Vuex store context object (no direct usage in this function).
     * @param {Object} options - An object containing asset and record information.
     * @param {string} options.asset - The type of asset to which the record belongs ('scripts', 'styles').
     * @param {Object} options.record - The updated record data.
     *
     * @returns {Promise<boolean>} A promise that resolves with a boolean indicating the success of the update operation.
     * @throws {Error} If there's an error while updating the record.
     */
    update({}, { asset, record }) {
      return new Promise((resolve, reject) => {
        const { security } = tenantCtx()
        const docRef = doc(collection(security, asset, record.location), record.id)

        getDoc(docRef).then(() => {
          updateDoc(docRef, record)
            .then(() => {
              store.dispatch('revisions/create', {
                event: RevisionEventType.UPDATE_SECURITY,
                id: `assets_${asset}`,
                newValue: record,
              })

              resolve(true)
            })
            .catch(error => {
              reject(error)
            })
        })
      })
    },

    /**
     * Upserts (creates or updates) a batch of asset records of a specified type in the security module.
     *
     * @param {Object} context - The Vuex store context object with the `dispatch` function.
     * @param {Object} options - An object containing asset and record information.
     * @param {string} options.asset - The type of asset to which the records belong ('scripts', 'styles').
     * @param {Array<Object>} options.records - An array of records to be upserted.
     *
     * @returns {Promise<boolean>} A promise that resolves with a boolean indicating the success of the upsert operation.
     * @throws {Error} If there's an error during the upsert operation.
     */
    upsertAssets({ dispatch }, { asset, records }) {
      return new Promise((resolve, reject) => {
        forEachAsync(records, async record => {
          const action = record?.id !== undefined ? 'update' : 'create'

          await dispatch(action, { asset, record }).then(() => {
            resolve(true)
          }).catch(error => {
            reject(error)
          })
        })

        store.dispatch('notify', {
          title: i18n.t('Great!'),
          body: i18n.t('{title} has been updated', { title: capitalizeFirstLetter(i18n.t(asset)) }),
        })

        store.dispatch('security/fetchAssets', { asset, locations: ['head'] })
      })
    },

    /**
     * Deletes a batch of asset records from the security module.
     *
     * @param {Object} _ - The Vuex store context object (no direct usage in this function).
     * @param {Array<Object>} records - An array of records to be deleted.
     *
     * @returns {Promise<void>} A promise that resolves when all records have been successfully deleted.
     * @throws {Error} If there's an error during the deletion process.
     */
    deleteAssets({}, records) {
      return new Promise((resolve, reject) => {
        const { security } = tenantCtx()

        forEachAsync(records, async record => {
          const assetCollection = collection(security, record.asset, record.location)

          try {
            await deleteDoc(doc(assetCollection, record.id))
          } catch (error) {
            reject(error)
          }
        })

        resolve()
      })
    },
  },
}
