/**
 * Transform the translations for the form usage
 *
 * @param {Object} translations
 *
 * @returns {Object}
 */
const transformForForm = translations => {
  const result = {}

  Object.keys(translations).forEach(lang => {
    const keys = Object.keys(translations[lang])

    result[lang] = keys.map(key => ({
      key,
      value: translations[lang][key],
    }))
  })

  return result
}

/**
 * Transforms the translations to the desired store format
 *
 * @param {string} translations
 *
 * @returns {Object}
 */
const transformForStorage = translations => {
  const data = []

  Object.entries(translations).forEach(entry => {
    const [key, value] = entry

    data[key] = value.reduce((acc, curr) => {
      acc[curr.key] = curr.value

      return acc
    }, {})
  })

  return data
}

export {
  transformForForm,
  transformForStorage,
}
