import i18n from '@/libs/i18n'

export default [
  {
    path: '/counters',
    name: 'counters-list',
    component: () => import('@/views/counters/counters-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Counters'),
      action: 'read',
      resource: 'counters',
    },
  },
  {
    path: '/counters/:id/edit',
    name: 'counters-edit',
    component: () => import('@/views/counters/counters-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Counters'),
      action: 'update',
      resource: 'counters',
      store: {
        subscribe: 'counters/SET_CURRENT',
        retrieve: 'counters/getCurrentData',
      },
      breadcrumb: [
        {
          text: i18n.t('Counters'),
          to: '/counters',
        },
        {
          text: '',
          field: 'name',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
]
