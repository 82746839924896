import {
  doc, getDocs, query, setDoc,
} from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins'
import store from '@/store'
import i18n from '@/libs/i18n'
import { RevisionEventType } from '@core/revisions/revisions'

export default {
  namespaced: true,
  state: {
    current: {
      dataAnonymizationInterval: null,
    },
  },
  getters: {
    getCurrent: state => state.current,
  },
  mutations: {
    UPDATE_CURRENT(state, payload) {
      state.current = payload
    },
  },
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, reject) => {
        const { privacy } = tenantCtx()
        const q = query(privacy)

        getDocs(q)
          .then(querySnapshot => {
            const [data] = querySnapshot.docs.map(docRef => ({ id: docRef.id, ...docRef.data() }))

            commit('UPDATE_CURRENT', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Upsert a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    upsert({ state }, payload) {
      return new Promise(resolve => {
        const { privacy } = tenantCtx()

        const id = payload.id || 'policy'
        const docRef = doc(privacy, id)

        setDoc(docRef, payload)
          .then(async () => {
            delete payload?.updatedAt
            delete state.current?.updatedAt

            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_PRIVACY,
              id,
              newValue: payload,
              previousValue: state.current,
            })

            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('{title} has been updated', { title: i18n.t('Privacy Policy') }),
            })

            await store.dispatch('privacy/fetch')

            resolve(payload)
          })
      })
    },
  },
}
