import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

export const convertUnixToTimestamp = (unix, format = 'MMMM Do YYYY, HH:mm') => dayjs.unix(unix).format(format)

export const convertUnixToFromNow = unix => dayjs.unix(unix).fromNow()

export const convertCustomFormat = (date, inputFormat, outputFormat) => dayjs(date, inputFormat).format(outputFormat)

export const getUnix = (timestamp = undefined) => dayjs(timestamp).unix()

export const getFutureUnix = (amount, unit) => dayjs().add(amount, unit).unix()

export const getTimestamp = (format = 'YYYY-MM-DD') => dayjs().format(format)

export const getTimestampWithSubtraction = (value, unit, format) => dayjs().subtract(value, unit).format(format)
