import i18n from '@/libs/i18n'

export default [
  {
    path: '/pages/list',
    name: 'pages-list',
    component: () => import('@/views/pages/pages-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Pages'),
      action: 'read',
      resource: 'pages',
    },
  },
  {
    path: '/pages/create',
    name: 'pages-page-create',
    component: () => import('@/views/pages/page-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Create Page'),
      action: 'create',
      resource: 'pages',
      breadcrumb: [
        {
          text: i18n.t('Pages'),
          to: '/pages/list',
        },
        {
          text: i18n.t('Create'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/:id/settings',
    name: 'pages-page-settings',
    component: () => import('@/views/pages/page-settings/Settings.vue'),
    meta: {
      pageTitle: i18n.t('Page Settings'),
      action: 'update',
      resource: 'pages',
      store: {
        subscribe: 'pages/SET_CURRENT',
        retrieve: 'pages/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Pages'),
          to: '/pages/list',
        },
        {
          text: '',
          field: 'name',
          active: true,
        },
        {
          text: i18n.t('Page Settings'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/:id/builder',
    name: 'pages-page-builder',
    component: () => import('@/views/pages/page-builder/PageBuilder.vue'),
    meta: {
      pageTitle: i18n.t('Page Builder'),
      action: 'update',
      resource: 'pages',
      layout: 'full', // full, vertical, horizontal
      breadcrumb: [
        {
          text: i18n.t('Pages'),
          to: '/pages/list',
        },
        {
          text: i18n.t('Page Builder'),
          active: true,
        },
      ],
    },
  },
]
