export default [
  {
    path: '/login',
    name: 'auth-login-redirect',
    async beforeEnter(to, _, next) {
      // Check if this is a special request,
      // e.g. invitation acceptance or user organization switch
      if (to.query.organization && to.query.invitation) {
        return next({ name: 'auth-login', params: { ...to.query, action: 'acceptInvite' } })
      }

      return next({ name: 'auth-login' })
    },
  },
]
