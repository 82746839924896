import {
  doc, updateDoc, getDocs, where, query, setDoc,
} from 'firebase/firestore'
import { getUnix } from '@/libs/date-format'
import { RevisionEventType } from '@core/revisions/revisions'
import i18n from '@/libs/i18n'
import store from '@/store'
import { getTenantContextInstance as tenantCtx } from '@/plugins'
import { allowed, FEATURES } from '@/auth'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getWebhooks: state => state.list,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { webhooks } = tenantCtx()
        const q = query(webhooks, where('deleted', '==', false))

        getDocs(q)
          .then(querySnapshot => {
            const data = querySnapshot.docs.map(docRef => ({ id: docRef.id, ...docRef.data() }))

            commit('UPDATE_LIST', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Creates a new document with the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.WEBHOOKS_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your {title} limit', { title: i18n.t('webhooks') }),
            variant: 'danger',
          })

          return
        }

        const { webhooks } = tenantCtx()
        const docRef = doc(webhooks)

        setDoc(docRef, { ...payload, id: docRef.id })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_WEBHOOKS,
              id: docRef.id,
              newValue: payload,
              previousValue: { },
            })
            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('{title} has been created', { title: i18n.t('Webhook') }),
            })
            resolve(payload)
          })
      })
    },

    /**
     * Updates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    update({}, payload) {
      return new Promise(resolve => {
        const { webhooks } = tenantCtx()
        const docRef = doc(webhooks, payload.id)

        updateDoc(docRef, payload)
          .then(async () => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_WEBHOOKS,
              id: payload.id,
              newValue: payload,
              previousValue: {},
            })
            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('{title} has been updated', { title: i18n.t('Webhook') }),
            })
            await store.dispatch('webhooks/fetchAll')
            resolve(payload)
          })
      })
    },

    /**
     * Soft deletes a document
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    softDelete({}, payload) {
      return new Promise(resolve => {
        const { webhooks } = tenantCtx()
        const docRef = doc(webhooks, payload.id)

        updateDoc(docRef, {
          deletedAt: getUnix(),
          deleted: true,
          activated: false,
        })
          .then(async () => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_WEBHOOKS,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })
            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('{title} has been deleted', { title: i18n.t('Webhook') }),
            })
            await store.dispatch('webhooks/fetchAll')
            resolve(payload)
          })
      })
    },
  },
}
