import { getAuth0Instance } from '@/plugins/auth0'

// eslint-disable-next-line import/prefer-default-export
export const routeProtection = (to, _, next) => {
  const authService = getAuth0Instance()

  const fn = () => {
    if (authService.isAuthenticated) {
      // Unprotected routes
      if (!to.meta.action) {
        return next()
      }

      if (authService.user.ability.can(to.meta.action, to.meta.resource)) {
        return next()
      }
    }

    return next({ name: 'errors-not-authorized' })
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
