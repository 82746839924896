import {
  getDoc, getDocs, doc, limit, onSnapshot, query, where,
} from 'firebase/firestore'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getAll: state => state.list.map(docRef => ({
      ...docRef.data(),
      id: docRef.id,
    })),
    getTenantDomains: state => state.list.map(docRef => ({
      ...docRef.data(),
      id: docRef.id,
    }))
      .filter(item => !item.system),
    getByType: state => type => state.list.map(docRef => ({
      snapshot: docRef,
      data: docRef.data(),
    }))
      .find(item => item.data.type === type && !item.data.system),
    getDefaultByType: state => type => state.list.map(docRef => docRef.data())
      .find(item => item.type === type && item.system),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    /**
     * Fetches all
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { domainMappings } = tenantCtx()

        getDocs(domainMappings)
          .then(querySnapshot => {
            commit('UPDATE_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the mapped domains'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a mapping by the given domain
     * Since only one mapping per domain SHOULD exist,
     * we limit the query to return 1
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchByDomain({}, domain) {
      return new Promise((resolve, reject) => {
        const { domainMappings } = tenantCtx()
        const q = query(domainMappings, where('domain', '==', domain), limit(1))

        getDocs(q)
          .then(querySnapshot => {
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Fetches all dnsAuthorizations
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchDNSAuthorizationsByDomain({}, domain) {
      return new Promise((resolve, reject) => {
        const { dnsAuthorizations } = tenantCtx()

        const docRef = doc(dnsAuthorizations, domain)

        getDoc(docRef)
          .then(docSnapshot => {
            resolve(docSnapshot.data())
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Listens to incoming domainMappings changes
     *
     * @param {Object}
     *
     * @returns {object}
     */
    async domainMappingsListener({ commit }) {
      const { domainMappings } = tenantCtx()

      const q = query(domainMappings, where('system', '==', false))

      // Now listen to changes and refetch
      return onSnapshot(q, snapshot => {
        commit('UPDATE_LIST', snapshot.docs)
      })
    },
  },
}
