import { httpsCallable } from 'firebase/functions'
import { functions } from '@/libs/firebase'

const ENVIRONMENT_DATA_KEY = 'novti-production-environment'

export default {
  namespaced: true,
  state: {
    donations: {
      dates: {},
      total: 0,
      totalDonors: 0,
    },
    subscribers: {
      dates: {},
      total: 0,
    },
    useProdEnvironment: localStorage.getItem(ENVIRONMENT_DATA_KEY) || true,
  },
  getters: {
    getDonations: state => state.donations,
    getSubscribers: state => state.subscribers,
    useProdEnvironment: state => JSON.parse(state.useProdEnvironment),
  },
  mutations: {
    UPDATE_DONATIONS_MONTH(state, payload) {
      state.donations = payload
    },
    UPDATE_SUBSCRIBERS_MONTH(state, payload) {
      state.subscribers = payload
    },
    UPDATE_PROD_ENVIRONMENT(state, payload) {
      state.donations = {
        dates: {},
        total: 0,
        totalDonors: 0,
      }

      state.subscribers = {
        dates: {},
        total: 0,
      }

      localStorage.setItem(ENVIRONMENT_DATA_KEY, payload)
      state.useProdEnvironment = payload
    },
  },
  actions: {
    /**
     * Fetch the tenant's analytics from the current month filtered by status & selected environment
     *
     * @param {Object}
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchAnalytics({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const fetchAnalytics = httpsCallable(functions, 'fetchTenantAnalytics')

        fetchAnalytics(payload)
          .then(response => {
            commit('UPDATE_DONATIONS_MONTH', response.data.donations)
            commit('UPDATE_SUBSCRIBERS_MONTH', response.data.subscribers)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
