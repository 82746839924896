import {
  query,
  where,
  limit,
  getDocs,
} from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    current: {},
  },
  getters: {
    getCurrent: state => state.current,
  },
  mutations: {
    SET_CURRENT(state, payload) {
      state.current = payload
    },
  },
  actions: {
    /**
       * Fetches a document by the given filter
       *
       * @param {Object} commit
       * @param {Object} filter - The filter
       * @param {string} filter.column - The column
       * @param {string} filter.operator - The operator
       * @param {string} filter.selector - The selector
       *
       * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
       */
    async fetchOneBy({ commit }, filter) {
      const { tenants } = tenantCtx()
      const q = query(tenants, where(filter.column, filter.operator, filter.selector), limit(1))

      return new Promise((resolve, reject) => {
        getDocs(q)
          .then(querySnapshot => {
            const [docSnapshot] = querySnapshot.docs

            // For now we just want this data
            const { company, tenant } = docSnapshot.data()

            commit('SET_CURRENT', { company, tenant })

            resolve({ company, tenant })
          })
          .catch(error => {
            reject(error)
            console.debug(error)
          })
      })
    },
  },
}
