import {
  getDocs,
  updateDoc,
} from 'firebase/firestore'
import { RevisionEventType } from '@core/revisions/revisions'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    current: {
      identity: {
        logo: null,
        favicon: null,
      },
    },
  },
  getters: {
    getCurrent: state => state.current,
  },
  mutations: {
    SET_CURRENT(state, payload) {
      state.current = payload
    },
  },
  actions: {
    /**
     * Fetches a the first document as current
     *
     * @param {string} id The current page ID
     */
    fetchFirst({}) {
      return new Promise((resolve, reject) => {
        const { organization } = tenantCtx()

        getDocs(organization)
          .then(querySnapshot => {
            const data = querySnapshot.docs.map(
              docRef => docRef,
            )

            resolve(data[0])
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the organization data'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a the first document as current
     *
     * @param {string} id The current page ID
     */
    fetchFirstAsCurrent({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchFirst')
          .then(res => {
            commit('SET_CURRENT', { ...res.data(), id: res.id })
            resolve(res.data())
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    update({ dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchFirst')
          .then(res => {
            updateDoc(res.ref, payload)
              .then(async () => {
                await store.dispatch('revisions/create', {
                  event: RevisionEventType.UPDATE_ORGANIZATION,
                  id: res.ref.id,
                  newValue: payload,
                  previousValue: state.current,
                })
                store.dispatch('notify', { body: i18n.t('Organization data has been updated') })
                dispatch('fetchFirstAsCurrent') // Update the current data as well
                resolve(true)
              })
              .catch(error => {
                store.dispatch('notify', {
                  body: i18n.t('Something went wrong updating the Organization data'),
                  variant: 'danger',
                })

                reject(error)
              })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}
