import { getDocs } from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getConnectorHelpers: state => state.list.find(item => item.Remover),
    getMappingHelpers: state => state.list.find(item => item.Aggregator),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { sharedHelpers } = tenantCtx()

        getDocs(sharedHelpers)
          .then(querySnapshot => {
            const data = JSON.parse(
              JSON.stringify(
                querySnapshot.docs.map(
                  docRef => docRef.data(),
                ),
              ),
            )

            commit('UPDATE_LIST', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
