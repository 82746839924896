import jsforce from 'jsforce'
import store from '@/store'

/**
  * Get query with return results from salesforce
  *
  * @param {Object}  conn  The connection
  * @param {string}  query The query to get executed
  *
  * @returns {Promise<{ campaigns: [], query: string }}
  */
export const getQuery = async (conn, query) => new Promise((resolve, reject) => {
  const queryExec = conn.query(query)

  queryExec.run((error, response) => {
    if (error) {
      reject(error)
    }

    resolve({ campaigns: response.records, query })
  })
})

/**
  * Setup the Salesforce Connection
  * If the access token is expired automaticly fetch a new one
  *
  * @param {Object} settings    The settings to use for the connection
  * @param {Object} env         The environment
  *
  * @returns {Promise}
  */
export const connection = (settings, env, version = '53.0') => {
  const cbPath = env === 'sandbox'
    ? process.env.VUE_APP_SF_SAND_OAUTH2_CB_PATH
    : process.env.VUE_APP_SF_PROD_OAUTH2_CB_PATH

  const currentConfig = {
    ...settings.data()[env],
    cbPath,
  }

  const conn = new jsforce.Connection({
    oauth2: {
      clientId: currentConfig.clientId,
      clientSecret: currentConfig.clientSecret,
      loginUrl: currentConfig.endpoint,
      redirectUri: `${process.env.VUE_APP_BASE_URL}/${currentConfig.cbPath}`,
    },
    instanceUrl: currentConfig.endpoint,
    accessToken: currentConfig.accessToken,
    refreshToken: currentConfig.refreshToken,
    version,
  })

  conn.on('refresh', token => {
    store.dispatch('connectorSettings/update', {
      id: settings.id,
      data: {
        [env]: {
          ...settings.data()[env],
          accessToken: token,
        },
      },
      skipNotify: true,
    })
  })

  return conn
}
