import {
  doc,
  setDoc,
  getDoc,
} from 'firebase/firestore'
import { cloneDeep, isEmpty, omit } from 'lodash'
import store from '@/store'
import i18n from '@/libs/i18n'
import { connection, getQuery } from '@/libs/jsforce'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import { RevisionEventType } from '@core/revisions/revisions'
import { getAuth0Instance } from '@/plugins/auth0'

export default {
  namespaced: true,
  state: {
    list: [],
    salesforceSettings: {},
    marketingCloudSettings: {},
    salesforceCampaignIds: [],
    retrievedSalesforceCampaigns: false,
    salesforceDestinationIdField: null,
  },
  getters: {
    getAll: state => state.list,
    getSalesforceSettings: state => state.salesforceSettings,
    getMarketingCloudSettings: state => state.marketingCloudSettings,
    getSalesforceCampaignIds: state => state.salesforceCampaignIds,
    getSalesforceDestinationIdField: state => state.salesforceDestinationIdField,
    hasRetrievedSalesforceCampaigns: state => state.retrievedSalesforceCampaigns,
    isSalesforceProdAuthenticated: state => !isEmpty(state.salesforceSettings.data?.production?.refreshToken),
    isSalesforceSandboxAuthenticated: state => !isEmpty(state.salesforceSettings.data?.sandbox?.refreshToken),
  },
  mutations: {
    UPDATE_SALESFORCE_SETTINGS(state, payload) {
      state.salesforceSettings = {
        snapshot: payload,
        data: payload.data(),
      }
    },
    UPDATE_MARKETINGCLOUD_SETTINGS(state, payload) {
      state.marketingCloudSettings = {
        snapshot: payload,
        data: payload.data(),
      }
    },
    UPDATE_SALESFORCE_CAMPAIGN_IDS(state, payload) {
      state.salesforceCampaignIds = payload
    },
    RETRIEVED_SALESFORCE_CAMPAIGN_IDS(state, payload) {
      state.retrievedSalesforceCampaigns = payload
    },
    SALESFORCE_DESTINATION_ID_FIELD(state, payload) {
      state.salesforceDestinationIdField = payload
    },
  },
  actions: {
    /**
     * Fetches a document by the given ID
     *
     * @param {string} id The logs document ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { connectorSettings } = tenantCtx()
        const docRef = doc(connectorSettings, id)

        getDoc(docRef)
          .then(docSnapshot => {
            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the connector settings'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches the salesforce settings
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchSalesforceSettings({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', 'salesforce')
          .then(res => {
            commit('UPDATE_SALESFORCE_SETTINGS', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches the marketing cloud settings
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchMarketingCloudSettings({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', 'marketingcloud')
          .then(res => {
            commit('UPDATE_MARKETINGCLOUD_SETTINGS', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates the a document by the given ID
     *
     * @param {*} payload
     * @returns {Promise}
     */
    update({}, payload) {
      return new Promise((resolve, reject) => {
        const successMessage = i18n.t('Connector Settings has been updated')
        const errorMessage = i18n.t('Something went wrong updating the {title}', { title: i18n.t('Connector Settings') })

        const { connectorSettings } = tenantCtx()
        const docRef = doc(connectorSettings, payload.id)

        // Throw an error if a combination of fields isn't there
        // eslint-disable-next-line no-restricted-syntax
        for (const env of ['sandbox', 'production']) {
          if (payload.data[env]) {
            if (
              !payload.data[env].clientId
              || !payload.data[env].clientSecret
            ) {
              store.dispatch('notify', {
                body: errorMessage,
                variant: 'danger',
              })
              reject(new Error(errorMessage))

              return
            }
          }
        }

        // Converted the getDoc() & updateDoc() to setDoc()
        // since we noticed some unwanted behavior with merging the data
        // occassionly the data was overwritten non-wanted data
        // ref: https://firebase.google.com/docs/firestore/manage-data/add-data#set_a_document
        // simular issue: https://github.com/firebase/flutterfire/issues/10331
        setDoc(docRef, payload.data, { merge: true }).then(() => {
          const revisionValue = cloneDeep(payload.data)
          const objectKeys = Object.keys(payload.data)

          // eslint-disable-next-line no-restricted-syntax
          for (const env of objectKeys) {
            if (/sandbox|production/.test(env)) {
              // Remove values that are sensitive
              revisionValue[env] = omit(
                payload.data[env],
                ['accessToken', 'refreshToken', 'clientSecret'],
              )
            }
          }

          store.dispatch('revisions/create', {
            event: RevisionEventType.UPDATE_CONNECTOR_SETTINGS,
            id: payload.id,
            newValue: revisionValue,
            previousValue: payload.initRevision,
          })

          if (payload?.skipNotify !== true) {
            store.dispatch('notify', { body: successMessage })
          }

          resolve(true)
        }).catch(err => {
          store.dispatch('notify', {

            body: errorMessage,
            variant: 'danger',
          })
          reject(err)
        })
      })
    },

    /**
     * Fetches the salesforce campaigns by the given query
     *
     * @param {Object}
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    async fetchSalesforceCampaignIds({ dispatch, commit }, payload) {
      const { env } = payload

      // Check if the salesforce settings are set
      const settings = await dispatch('fetchSalesforceSettings')

      let campaignData
      let errorMessage

      const query = settings.data()?.queries?.campaigns
      const destinationIdField = settings.data()?.queries?.destinationField || null
      const connectorSettings = settings.data()[env] || false

      if (!query || !connectorSettings) {
        // Salesforce shouldn't be called if query is empty.
        commit('RETRIEVED_SALESFORCE_CAMPAIGN_IDS', true)
        commit('UPDATE_SALESFORCE_CAMPAIGN_IDS', [])

        return new Promise(resolve => {
          resolve(i18n.t('No campaigns'))
        })
      }

      try {
        const conn = connection(settings, env)

        campaignData = await getQuery(conn, query)
      } catch (error) {
        errorMessage = error.message
      }

      return new Promise((resolve, reject) => {
        if (errorMessage) {
          store.dispatch('notify', {
            body: i18n.t('Unable to fetch campaigns'),
            variant: 'danger',
          })

          // Only relevant for users that can update the connectors.
          if (getAuth0Instance().user.ability.can('update', 'connectors')) {
            store.dispatch('notify', {
              body: `Salesforce error: ${errorMessage}`,
              variant: 'danger',
            })
          }

          commit('RETRIEVED_SALESFORCE_CAMPAIGN_IDS', true)
          commit('UPDATE_SALESFORCE_CAMPAIGN_IDS', [])

          reject(errorMessage)
        } else {
          if (destinationIdField) {
            campaignData?.campaigns.map(item => {
              item.destinationIdLabelWithCampaignId = `${item[destinationIdField]} / (${item.Name})`
            })
          }

          commit('UPDATE_SALESFORCE_CAMPAIGN_IDS', campaignData?.campaigns)
          commit('SALESFORCE_DESTINATION_ID_FIELD', destinationIdField)

          resolve(campaignData)
        }
      })
    },
  },
}
