import store from '@/store'
import i18n from '@/libs/i18n'
import { silentRoles } from '@/views/users/base'
import { getTenantContextInstance as tenantCtx } from '@/plugins'

export const FEATURES = {
  ADMIN_PANEL_MULTILANGUAGE: 'ADMIN_PANEL_MULTILANGUAGE',
  FORM_CONFIGURATIONS_QUOTA: 'FORM_CONFIGURATIONS_QUOTA',
  PREFERENCE_CENTERS_QUOTA: 'PREFERENCE_CENTERS_QUOTA',
  USERS_QUOTA: 'USERS_QUOTA',
  PAGES_QUOTA: 'PAGES_QUOTA',
  FORMS_QUOTA: 'FORMS_QUOTA',
  FORM_FIELDS_QUOTA: 'FORM_FIELDS_QUOTA',
  CLIENT_ADMIN_API_QUOTA: 'CLIENT_ADMIN_API_QUOTA',
  COUNTERS_QUOTA: 'COUNTERS_QUOTA',
  WEBHOOKS_QUOTA: 'WEBHOOKS_QUOTA',
  REGULAR_EXPRESSIONS_QUOTA: 'REGULAR_EXPRESSIONS_QUOTA',
  THEMES_QUOTA: 'THEMES_QUOTA',
  ELEMENTS_QUOTA: 'ELEMENTS_QUOTA',
  INTEGRATIONS_GTM_INTEGRATION: 'INTEGRATIONS_GTM',
  INTEGRATIONS_POSTCODE_API_INTEGRATION: 'INTEGRATIONS_POSTCODE_API_INTEGRATION',
  ADMIN_PANEL_THEME_CUSTOMIZER: 'ADMIN_PANEL_THEME_CUSTOMIZER',
  DASHBOARD_REALTIME_LATEST_TRANSACTIONS_VIEW: 'DASHBOARD_REALTIME_LATEST_TRANSACTIONS_VIEW',
  DASHBOARD_MONTHLY_PAYMENTS_CHART: 'DASHBOARD_MONTHLY_PAYMENTS_CHART',
  DASHBOARD_MONTHLY_SUBSCRIBERS_CHART: 'DASHBOARD_MONTHLY_SUBSCRIBERS_CHART',
  DASHBOARD_LATEST_REVISIONS_VIEW: 'DASHBOARD_LATEST_REVISIONS_VIEW',
  PAGES_REVISIONS: 'PAGES_REVISIONS',
  FORMS_REVISIONS: 'FORMS_REVISIONS',
  FORM_CONFIGURATIONS_MAPPING_DASHBOARD: 'FORM_CONFIGURATIONS_MAPPING_DASHBOARD',
  FORM_CONFIGURATIONS_REVISIONS: 'FORM_CONFIGURATIONS_REVISIONS',
  PREFERENCE_CENTERS_REVISIONS: 'PREFERENCE_CENTERS_REVISIONS',
  FORMS_TRANSLATIONS: 'FORMS_TRANSLATIONS',
  INTEGRATIONS_CUSTOM_INTEGRATIONS_QUOTA: 'INTEGRATIONS_CUSTOM_INTEGRATIONS_QUOTA',
  // NOT YET IMPLEMENTED
  FORM_CONFIGURATIONS_SALESFORCE_INTEGRATION: 'FORM_CONFIGURATIONS_SALESFORCE_INTEGRATION',
  // NOT YET IMPLEMENTED
  FORM_CONFIGURATIONS_FINDOCK_V1_INTEGRATION: 'FORM_CONFIGURATIONS_FINDOCK_V1_INTEGRATION',
  // NOT YET IMPLEMENTED
  FORM_CONFIGURATIONS_MARKETING_CLOUD_INTEGRATION: 'FORM_CONFIGURATIONS_MARKETING_CLOUD_INTEGRATION',
  // NOT YET IMPLEMENTED
  FORM_CONFIGURATIONS_FINDOCK_V2_INTEGRATION: 'FORM_CONFIGURATIONS_FINDOCK_V2_INTEGRATION',
  // NOT YET IMPLEMENTED
  FORM_CONFIGURATIONS_CONVERSE_INTEGRATION: 'FORM_CONFIGURATIONS_CONVERSE_INTEGRATION',
  // NOT YET IMPLEMENTED
  FORM_CONFIGURATIONS_CUSTOM_CONNECTORS_INTEGRATION: 'FORM_CONFIGURATIONS_CUSTOM_CONNECTORS_INTEGRATION',
  // NOT YET IMPLEMENTED
  SUBDOMAIN_MAPPINGS: 'SUBDOMAIN_MAPPINGS',
  // NOT YET IMPLEMENTED
  SUPPORT_TICKET_SYSTEM: 'SUPPORT_TICKET_SYSTEM',
  // NOT YET IMPLEMENTED
  USAGE_DASHBOARD: 'USAGE_DASHBOARD',
  // NOT YET IMPLEMENTED
  BILLING_STRIPE_CUSTOMER_PORTAL: 'BILLING_STRIPE_CUSTOMER_PORTAL',
  // NOT YET IMPLEMENTED
  FORMS_PRODUCTS_COMPONENT: 'FORMS_PRODUCTS_COMPONENT',
  // NOT YET IMPLEMENTED
  FORMS_GIVING_EXAMPLE_COMPONENT: 'FORMS_GIVING_EXAMPLE_COMPONENT',
  // NOT YET IMPLEMENTED
  FORMS_CONDITIONAL_OPTIONS: 'FORMS_CONDITIONAL_OPTIONS',
  // NOT YET IMPLEMENTED
  FORMS_SURVEY_COMPONENT: 'FORMS_SURVEY_COMPONENT',
  // NOT YET IMPLEMENTED
  FORMS_ADDRESS_COMPONENT: 'FORMS_ADDRESS_COMPONENT',
  // NOT YET IMPLEMENTED
  FORMS_POSTCODE_API_COMPONENT: 'FORMS_POSTCODE_API_COMPONENT',
  // NOT YET IMPLEMENTED
  FORMS_RECAPTCHA_COMPONENT: 'FORMS_RECAPTCHA_COMPONENT',
  // NOT YET IMPLEMENTED
  ROOT_DOMAIN_MAPPINGS: 'ROOT_DOMAIN_MAPPINGS',
}

/**
 * Verifies if the requested action will not result in exceeding the limits set by the active subscription for the tenant.
 *
 * @param {string} quota the limit value set by the package in the tenant's subscription
 * @param {number} total the current amount of the resource
 *
 * @returns {boolean}
 */
const isQuotaReached = (quota, total) => (total >= parseFloat(quota))

/**
 * This is the main function that holds the logic for the feature toggle based restrictions.
 * With one of the featureKeys defined in the FEATURES variable you can verify if the tenant is allowed to perform the action(s) that are associated with the feature key.
 *
 * @param {string} featureKey the key name of the feature toggle
 *
 * @returns {boolean}
 */
export const allowed = featureKey => {
  const { packageFeatures } = tenantCtx()

  switch (featureKey) {
    case FEATURES.PAGES_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['pages/getAll'].length,
      )
    case FEATURES.FORMS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['forms/getAll'].length,
      )
    case FEATURES.FORM_FIELDS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['formFields/getAll'].length,
      )
    case FEATURES.REGULAR_EXPRESSIONS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['regularExpressions/getAll'].length,
      )
    case FEATURES.FORM_CONFIGURATIONS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['formConfigurations/getAll'].length,
      )
    case FEATURES.PREFERENCE_CENTERS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['preferenceCenters/getAll'].length,
      )
    case FEATURES.USERS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['users/getAll'].filter(
          user => !silentRoles.includes(user.role),
        ).length,
      )
    case FEATURES.COUNTERS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['counters/getAll'].length,
      )
    case FEATURES.THEMES_QUOTA:
      if (packageFeatures[featureKey] === undefined) {
        console.debug(i18n.t('Feature key for Themes quota not found.'))

        return false
      }

      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['themes/getTenantThemes'].length,
      )
    case FEATURES.ELEMENTS_QUOTA:
      if (packageFeatures[featureKey] === undefined) {
        console.debug(i18n.t('Feature key for Elements quota not found.'))

        return false
      }

      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['elements/getElements'].length,
      )
    case FEATURES.INTEGRATIONS_CUSTOM_INTEGRATIONS_QUOTA:
      return !isQuotaReached(
        packageFeatures[featureKey],
        store.getters['integrations/getCustomIntegrations'].length,
      )
    default:
      return JSON.parse(packageFeatures[featureKey] || 'false')
  }
}
