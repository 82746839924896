export const deviceManager = {
  deviceManager: {
    devices: [
      {
        name: 'Mobile portrait',
        width: '375px',
      },
      {
        name: 'Tablet Pro',
        width: '1024px',
      },
      {
        name: 'Tablet',
        width: '765px',
      },
      {
        name: 'Desktop',
        width: '1281px',
        widthMedia: '',
      },
    ],
  },
}
