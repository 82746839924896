import {
  addDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { Revision, RevisionEventType, RevisionType } from '@core/revisions/revisions'
import { camelCase } from 'lodash'
import grapesjs from 'grapesjs'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import store from '@/store'
import i18n from '@/libs/i18n'
import { bootstrapStorageManager } from '@/views/pages/plugins/grapesjs/bootstrap/storage-manager'
import options from '@/views/pages/plugins/grapesjs/config/options'
import router from '@/router'
import { getUnix, convertUnixToFromNow } from '@/libs/date-format'

export default {
  namespaced: true,
  state: {
    list: [],
    current: [],
  },
  getters: {
    getList: state => state.list,
    getCurrent: state => state.current,
    getById: state => id => state.list.find(el => el.id === id),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
    UPDATE_CURRENT(state, payload) {
      state.current = payload
    },
  },
  actions: {
    /**
     * Fetches all revisions.
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, limitAmount = 5) {
      return new Promise((resolve, reject) => {
        const { revisions } = tenantCtx()
        const q = query(revisions, orderBy('createdAt', 'desc'), limit(limitAmount))

        getDocs(q)
          .then(querySnapshot => {
            const docs = querySnapshot.docs.map(doc => {
              const data = doc.data()
              const createdAt = convertUnixToFromNow(data.createdAt)

              return { ...data, id: doc.id, createdAt }
            })

            commit('UPDATE_LIST', docs)
            resolve(docs)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches a document by the given ID
     *
     * @param {string} id The revisions document ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { revisions } = tenantCtx()
        const q = query(revisions, where('referenceId', '==', id), orderBy('createdAt', 'desc'), limit(1))

        getDocs(q)
          .then(querySnapshot => {
            const [doc] = querySnapshot.docs

            if (!doc) {
              resolve(false)
            }

            resolve(doc.data())
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches revisions for document by the current reference ID
     *
     * @param {string} id
     */
    fetchForCurrent({ commit }, id) {
      return new Promise(async (resolve, reject) => {
        try {
          let whereClause

          switch (router.currentRoute.meta.resource) {
            case ('organization'):
              whereClause = where('event', '==', RevisionEventType.UPDATE_ORGANIZATION)
              break
            case ('connectors'):
              whereClause = where('event', '==', RevisionEventType.UPDATE_CONNECTOR_SETTINGS)
              break
            case ('domain_mappings'):
              whereClause = where('event', '==', RevisionEventType.UPDATE_DOMAIN_MAPPINGS)
              break
            case ('privacy'):
              whereClause = where('event', '==', RevisionEventType.UPDATE_PRIVACY)
              break
            case ('security'):
              whereClause = where('event', '==', RevisionEventType.UPDATE_SECURITY)
              break
            default:
              whereClause = where('referenceId', '==', id)
              break
          }

          const { revisions } = tenantCtx()
          const q = query(revisions, whereClause)
          const querySnapshot = await getDocs(q)

          const docs = querySnapshot.docs.map(doc => {
            const data = doc.data()
            const createdAt = convertUnixToFromNow(data.createdAt)

            return {
              ...data,
              id: doc.id,
              createdAt,
              createdAtUnix: data.createdAt,
            }
          })

          const sortedDocs = docs.sort((a, b) => b.createdAtUnix - a.createdAtUnix)

          commit('UPDATE_CURRENT', sortedDocs)
          resolve(sortedDocs)
        } catch (error) {
          console.debug(error)

          reject(error)
        }
      })
    },

    /**
     * Create revision.
     *
     * @param {String} event
     * @param {String} id
     * @param {Object} newValue
     * @param {Object} previousValue
     *
     * @returns {Promise}
     */
    create({}, {
      event, id, newValue, previousValue = {},
    }) {
      return new Promise(async resolve => {
        try {
          const { revisions } = tenantCtx()
          const me = store.getters['users/me']

          const user = (me.lastName && me.firstName) ? `${me.firstName} ${me.lastName}` : me.email

          const { ...revision } = new Revision(event, id, user, previousValue, newValue, me.email)

          await addDoc(revisions, revision)
          await store.dispatch('revisions/fetchForCurrent', id)

          resolve(revision)
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            body: i18n.t('Something went wrong with creating {title}', { title: i18n.t('Revision') }),
            variant: 'danger',
          })
        }
      })
    },

    /**
     * Restores a revision.
     *
     * @param {Object} obj
     * @param {Object} payload.revision - The revision to restore
     * @param {Object} payload.currentValue - The current value
     *
     * @returns {Promise}
     */
    restoreRevision({ }, { revision, currentValue }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { referenceId, event, newValue } = revision
          const tenantContext = mapRevisionEventTypeToTenantCtx(event)

          const docRef = doc(tenantContext, referenceId)

          if (revision.event === RevisionEventType.UPDATE_FORMS) {
            await store.dispatch('forms/updateCurrent', revision.newValue)
          }

          if (revision.event === RevisionEventType.UPDATE_PAGES) {
            const editor = grapesjs.init(options())
            const currentPage = { data: revision.newValue, snapshot: { id: referenceId } }

            bootstrapStorageManager(editor, currentPage)

            editor.loadData(currentPage.data.recipes)
            editor.store()
          } else {
            await setDoc(docRef, {
              ...revision.newValue,
              updatedAt: getUnix(),
            })

            await store.dispatch('revisions/create', {
              event, id: referenceId, newValue, previousValue: currentValue,
            })
          }

          await store.dispatch('notify', { title: i18n.t('Great!'), body: i18n.t('Revision has been restored 🚀') })
          await store.dispatch('revisions/fetchForCurrent', referenceId)

          resolve(true)
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            body: i18n.t('Something went wrong with restoring revision'),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },
  },
}

const mapRevisionEventTypeToTenantCtx = revisionEventType => {
  const tenantCtxName = Object.keys(RevisionType)
    .find(revisionTypeKey => revisionEventType.includes(revisionTypeKey))

  return tenantCtx()[camelCase(tenantCtxName)]
}
