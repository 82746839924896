import i18n from '@/libs/i18n'

export default [
  {
    path: '/general-settings/organization',
    name: 'general-settings-organization-edit',
    component: () => import('@/views/general-settings/organization/organization-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Organization'),
      action: 'update',
      resource: 'organization',
    },
  },
  {
    path: '/general-settings/domain-mappings',
    name: 'general-settings-domain-mappings-edit',
    component: () => import('@/views/general-settings/domain-mappings/domain-mappings-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Domain Mappings'),
      action: 'update',
      resource: 'domain_mappings',
    },
  },
  {
    path: '/general-settings/domain-mappings/status',
    name: 'general-settings-domain-mappings-status',
    component: () => import('@/views/general-settings/domain-mappings/domain-mappings-verification-status/Status.vue'),
    meta: {
      pageTitle: i18n.t('Domain Mappings Status'),
      action: 'update',
      resource: 'domain_mappings',
    },
  },

  // Connectors
  {
    path: '/general-settings/connectors',
    name: 'general-settings-connectors-edit',
    component: () => import('@/views/general-settings/connectors/connectors-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Connectors'),
      action: 'read',
      resource: 'connectors',
    },
  },
  {
    path: '/salesforce/production/oauth/callback',
    name: 'salesforce-production-oauth-callback',
    meta: {
      action: 'update',
      resource: 'connectors',
    },
    beforeEnter: (to, _, next) => {
      next({
        name: 'salesforce-oauth-callback',
        query: {
          env: 'production',
          code: to.query.code,
        },
      })
    },
  },
  {
    path: '/salesforce/sandbox/oauth/callback',
    name: 'salesforce-sandbox-oauth-callback',
    meta: {
      action: 'update',
      resource: 'connectors',
    },
    beforeEnter: (to, _, next) => {
      next({
        name: 'salesforce-oauth-callback',
        query: {
          env: 'sandbox',
          code: to.query.code,
        },
      })
    },
  },
  {
    path: '/salesforce/oauth/callback',
    name: 'salesforce-oauth-callback',
    component: () => import('@/views/general-settings/connectors/callbacks/SalesforceOAuthCallback.vue'),
    meta: {
      action: 'update',
      resource: 'connectors',
      layout: 'vertical',
    },
  },
  // Webhooks
  {
    path: '/general-settings/webhooks',
    name: 'general-settings-webhooks-list',
    component: () => import('@/views/general-settings/webhooks/webhooks-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Webhooks'),
      action: 'read',
      resource: 'webhooks',
    },
  },
  {
    path: '/general-settings/privacy',
    name: 'general-settings-privacy-edit',
    component: () => import('@/views/general-settings/privacy/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Privacy'),
      action: 'read',
      resource: 'privacy',
    },
  },
  {
    path: '/general-settings/security',
    name: 'general-settings-security-edit',
    component: () => import('@/views/general-settings/security/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Security'),
      resource: 'security',
      action: 'read',
    },
  },
  {
    path: '/general-settings/webhooks/create',
    name: 'general-settings-webhooks-create',
    component: () => import('@/views/general-settings/webhooks/webhooks-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Webhooks create'),
      action: 'create',
      resource: 'webhooks',
    },
  },
  // Developers
  {
    path: '/general-settings/developers/list',
    name: 'general-settings-developers-list',
    component: () => import('@/views/general-settings/developers/developers-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Developers'),
      action: 'read',
      resource: 'developers_console',
    },
  },
  {
    path: '/general-settings/developers/create',
    name: 'general-settings-developers-create',
    component: () => import('@/views/general-settings/developers/developers-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Create credentials'),
      action: 'create',
      resource: 'client_admin_api_credentials',
    },
  },
  // Integrations
  {
    path: '/general-settings/integrations',
    name: 'general-settings-integrations-list',
    component: () => import('@/views/general-settings/integrations/integrations-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Integrations'),
      action: 'read',
      resource: 'integrations',
    },
  },
  {
    path: '/general-settings/integrations/create',
    name: 'general-settings-integrations-create',
    component: () => import('@/views/general-settings/integrations/integrations-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Create integration'),
      action: 'create',
      resource: 'integrations',
      breadcrumb: [
        {
          text: i18n.t('Integrations'),
          to: '/general-settings/integrations',
        },
        {
          text: i18n.t('Create'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/integrations/:id',
    name: 'general-settings-integrations-edit',
    component: () => import('@/views/general-settings/integrations/integrations-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Edit integration'),
      action: 'update',
      resource: 'integrations',
      store: {
        subscribe: 'integrations/SET_CURRENT',
        retrieve: 'integrations/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Integrations'),
          to: '/general-settings/integrations',
        },
        {
          text: '',
          field: 'name',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/integrations/:id/callback',
    name: 'general-settings-integrations-callback',
    component: () => import('@/views/general-settings/integrations/integrations-callback/Callback.vue'),
    meta: {
      pageTitle: i18n.t('Callback integration'),
      action: 'update',
      resource: 'integrations',
      store: {
        subscribe: 'integrations/SET_CURRENT',
        retrieve: 'integrations/getCurrent',
      },
      breadcrumb: [
        {
          text: i18n.t('Integrations'),
          to: '/general-settings/integrations',
        },
        {
          text: '',
          field: 'name',
          active: true,
        },
        {
          text: i18n.t('Callback'),
          active: true,
        },
      ],
    },
  },
]
