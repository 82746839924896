import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore'
import { getUnix } from '@/libs/date-format'
import { RevisionEventType } from '@core/revisions/revisions'
import { constructQuery } from '@core/utils/utils'
import { uniqBy } from 'lodash'
import { allowed, FEATURES } from '@/auth'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    list: [],
    filteredList: [],
    current: {},
    currentData: {},
    node: {
      selected: null,
      created: null,
      edited: null,
      remove: null,
    },
  },
  getters: {
    getCurrent: state => state.current,
    getCurrentData: state => state.currentData,
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getFilteredList: state => state.filteredList.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getStoreMappings: state => JSON.parse(state.currentData.storeMappings ?? '{}'),
    getPrefillMappings: state => JSON.parse(state.currentData.prefillMappings ?? '{}'),

    // Define the getters for the `TreeNode`
    getSelectedNode: state => state.node.selected,
    getCreatedNode: state => state.node.created,
    getRemovedNode: state => state.node.remove,
    getEditedNode: state => state.node.edited,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      const { docs, pagination } = payload

      state.list = (!pagination) ? docs : uniqBy([...state.list, ...docs], 'id')
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.filteredList = payload
    },
    SET_CURRENT(state, payload) {
      state.current = payload
      state.currentData = ({ ...payload.data(), id: payload.id })
    },
    // Define the mutations for the `TreeNode` changes
    SET_NODE_SELECTED(state, payload) {
      state.node.selected = payload
    },
    SET_NODE_CREATED(state, payload) {
      state.node.created = payload
    },
    SET_NODE_EDITED(state, payload) {
      state.node.edited = payload
    },
    NODE_REMOVE(state, payload) {
      state.node.remove = payload
    },
  },
  actions: {
    /**
     * Fetches all
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        const { preferenceCenters } = tenantCtx()

        const { queryOptions = {}, pagination = false } = payload

        const q = constructQuery(preferenceCenters, 'title', {
          ...queryOptions,
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', { docs: querySnapshot.docs, pagination })
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Preference Centers') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the given ID.
     *
     * @param {Object} id The page ID.
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({ }, id) {
      return new Promise((resolve, reject) => {
        const { preferenceCenters } = tenantCtx()
        const docRef = doc(preferenceCenters, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            if (docSnapshot.data().deletedAt) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Preference Center') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID.
     *
     * @param {string} id The current page ID
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    update({ state }, { previousValue, value }) {
      return new Promise((resolve, reject) => {
        updateDoc(state.current.ref, value)
          .then(async () => {
            await store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_PREFERENCE_CENTERS,
              id: state.current.ref.id,
              newValue: value,
              previousValue,
            })

            store.dispatch('notify', { body: i18n.t('{title} has been updated', { title: i18n.t('Preference Center') }) })

            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the {title}', { title: i18n.t('Preference Center') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { preferenceCenters } = tenantCtx()

        const q = constructQuery(preferenceCenters, 'updatedAt', {
          ...queryOptions,
          where: [...queryOptions.where, where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_FILTERED_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('preference center') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * resets the filter list
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    resetFiltered({ commit }) {
      return new Promise(resolve => {
        commit('UPDATE_FILTERED_LIST', [])
        resolve()
      })
    },

    /**
     * Creates a document by the given payload.
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({ }, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.PREFERENCE_CENTERS_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your {title} limit', { title: i18n.t('preference centers') }),
            variant: 'danger',
          })

          return
        }

        const { preferenceCenters } = tenantCtx()
        const docRef = doc(preferenceCenters)

        setDoc(docRef, { ...payload, id: docRef.id, deletedAt: null })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_PREFERENCE_CENTERS,
              id: docRef.id,
              newValue: payload,
              previousValue: {},
            })

            store.dispatch('notify', { body: i18n.t('A new {title} has been created 🚀', { title: i18n.t('Preference Center') }) })
            resolve(docRef)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the {title}', i18n.t('Preference Center')),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Soft deletes a document
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    softDelete({ }, payload) {
      return new Promise((resolve, reject) => {
        const { preferenceCenters } = tenantCtx()
        const docRef = doc(preferenceCenters, payload.id)

        updateDoc(docRef, {
          deletedAt: getUnix(),
        }).then(() => {
          store.dispatch('revisions/create', {
            event: RevisionEventType.DELETE_PREFERENCE_CENTERS,
            id: payload.id,
            newValue: {},
            previousValue: payload,
          })
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Duplicates a document
     *
     * @param {Object}
     * @param {string} id The id of the document to duplicate
     *
     * @returns {Promise}
     */
    duplicate({}, id) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.PREFERENCE_CENTERS_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your {title} limit', { title: i18n.t('preference centers') }),
            variant: 'danger',
          })

          return
        }

        const { preferenceCenters } = tenantCtx()
        const docRef = doc(preferenceCenters, id)
        const timestamp = getUnix()

        const newDocRef = doc(preferenceCenters)

        getDoc(docRef)
          .then(docSnapshot => {
            let duplicateSlug = `${docSnapshot.data().slug}-${timestamp}`

            // Additional check to make sure the slug is unique
            // Add random number next to the timestamp if thats not the case.
            getDocs(
              query(
                preferenceCenters,
                where('slug', '==', duplicateSlug),
                where('deletedAt', '==', null),
              ),
            ).then(querySnapshot => {
              if (querySnapshot) {
                duplicateSlug = `${duplicateSlug}-${Math.floor(Math.random() * 99999999)}`
              }
            })

            const data = {
              ...docSnapshot.data(),
              id: newDocRef.id,
              slug: duplicateSlug,
              // Set all timestamp properties.
              createdAt: timestamp,
              updatedAt: timestamp,
              deletedAt: null,
            }

            setDoc(newDocRef, data)
              .then(newPageRef => {
                store.dispatch('revisions/create', {
                  event: RevisionEventType.CREATE_PREFERENCE_CENTERS,
                  id: docRef.id,
                  newValue: data,
                  previousValue: {},
                })

                router.push({
                  name: 'preference-centers-edit',
                  params: { id: newDocRef.id },
                })

                store.dispatch('notify', {
                  title: i18n.t('Great!'),
                  body: i18n.t('A new {title} has been duplicated 🚀', { title: i18n.t('preference center') }),
                })

                resolve(newPageRef)
              }).catch(error => { reject(error) })

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong duplicating the {title}', { title: i18n.t('preference center') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },
  },
}
