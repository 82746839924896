/* eslint-disable import/no-cycle */
import Vue from 'vue'
import {
  query,
  where,
  getDoc,
  getDocs,
  collectionGroup,
} from 'firebase/firestore'
import {
  httpsCallable,
} from 'firebase/functions'
import store from '@/store'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import { AbilityBuilder, Ability } from '@casl/ability'
import { setLocale } from '@/@core/utils/utils'
import { TenantContextPlugin } from '../tenant'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

let instance

export const getAuth0Instance = () => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  const {
    db,
    auth,
    storage,
    themesDb,
    functions,
    clientThemesRootStorage,
  } = options

  instance = new Vue({
    data() {
      return {
        me: {},
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      }
    },
    async created() {
      try {
        this.auth0Client = await createAuth0Client({
          domain: options.domain,
          client_id: options.clientId,
          audience: options.audience,
          redirect_uri: redirectUri,
          scope: 'openid profile',
          advancedOptions: {
            defaultScope: 'email',
          },
        })

        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()

        // Get the current route's queries to check if it is a special request,
        // e.g. invitation acceptance or user organization switch
        const { organization, invitation, action } = router.currentRoute.params

        if (!this.isAuthenticated) {
          // Check if we have to handle the user invitation acceptance
          if (action === 'acceptInvite') {
            this.auth0Client.loginWithRedirect({
              organization,
              invitation,
            })
          } else {
            this.auth0Client.loginWithRedirect({
              appState: {
                targetUrl: window.location.pathname + window.location.search,
              },
            })
          }
        }

        // Now, let us authenticate the user against Firebase using a custom token generated by the Cloud Function
        const createCustomFirebaseAuthToken = httpsCallable(functions, 'createCustomFirebaseAuthToken')

        await createCustomFirebaseAuthToken({ jwt: await this.getTokenSilently() })
          .then(result => {
            // Authenticate here...
            signInWithCustomToken(auth, result.data.token)
              .then(async () => {
                // Set the user abilities from Auth0's permissions
                const ability = this.composeUserAbilities(Object.keys(result.data.permissions) ?? [])

                Vue.use(abilitiesPlugin, ability)
                this.setUserAbility(ability)

                const userTenantData = []
                let currentUserSnapshot

                const tenantsUserSnapshot = await getDocs(
                  query(
                    collectionGroup(db, 'users'),
                    where('email', '==', result.data.email),
                    where('status', '==', 'ACTIVE'),
                  ),
                )

                // eslint-disable-next-line no-restricted-syntax
                for (const userDoc of tenantsUserSnapshot.docs) {
                  const [, tenant] = userDoc.ref.path.split('/')
                  const docData = userDoc.data()
                  const { invitation: tenantInvitation } = docData

                  if (tenantInvitation) userTenantData.push({ id: tenantInvitation.organization_id, tenant })

                  if (tenant === result.data.tenant) currentUserSnapshot = userDoc
                }

                // Retake, since `updateDoc` seems not to update variable `currentUserSnapshot`.
                currentUserSnapshot = await getDoc(currentUserSnapshot.ref)

                const data = currentUserSnapshot.data()

                // Pass both the data and the snapshot for quick access later.
                this.me = {
                  data,
                  snapshot: currentUserSnapshot,
                  organization: {
                    tenant: result.data.tenant,
                    id: result.data.orgId,
                  },
                  tenants: userTenantData,
                }

                setLocale(data.locale ?? null)

                store.commit('users/SET_ME', data)

                // After successful authentication, boot the tenant context passing the Auth0 organization ID.
                Vue.use(TenantContextPlugin, {
                  db,
                  storage,
                  themesDb,
                  clientThemesRootStorage,
                  tenant: result.data.tenant,
                })

                this.loading = false
              })
              .catch(error => {
                console.debug(error)

                this.logout()
              })
          })
          .catch(error => {
            console.debug(error)

            this.logout()
          })
      }
    },
    methods: {
      async loginWithPopup(o) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(o)

          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.error = null
        } catch (e) {
          console.debug(e)

          this.error = e
        } finally {
          this.popupOpen = false
        }
      },
      async handleRedirectCallback() {
        this.loading = true

        try {
          await this.auth0Client.handleRedirectCallback()

          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          console.debug(e)

          this.error = e
        } finally {
          this.loading = false
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      async logout(o) {
        // Firebase auth signout
        await signOut(auth)

        // Auth0 logout
        return this.auth0Client.logout(o)
      },
      async switchTenant(organization) {
        await this.auth0Client.loginWithRedirect({ organization })
      },
      composeUserAbilities(permissions) {
        const { can, rules } = new AbilityBuilder()

        // eslint-disable-next-line no-restricted-syntax
        for (const permission of permissions) {
          const actionSubject = permission.split(':')

          can(actionSubject[0], actionSubject[1])
        }

        return new Ability(rules)
      },
      setUserAbility(userAbilities) {
        this.user.ability = userAbilities
      },
    },
  })

  return instance
}

export const Auth0WrapperPlugin = {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = useAuth0(options)
  },
}
