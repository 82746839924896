export default [
  {
    path: '/error',
    name: 'errors-error',
    component: () => import('@/views/errors/Error.vue'),
    meta: {
      layout: 'horizontal',
      action: 'read',
      resource: 'errors',
    },
  },
  {
    path: '/error-404',
    name: 'errors-404',
    component: () => import('@/views/errors/Error404.vue'),
    meta: {
      layout: 'horizontal',
      action: 'read',
      resource: 'errors',
    },
  },
  {
    path: '/not-authorized',
    name: 'errors-not-authorized',
    component: () => import('@/views/errors/NotAuthorized.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
]
