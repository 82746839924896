/* eslint-disable no-param-reassign */
// External
import {
  getBlob,
  uploadString,
  deleteObject,
  ref as storageRef,
} from 'firebase/storage'
import {
  doc,
  where,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore'

// Local
import store from '@/store'
import i18n from '@/libs/i18n'
import { getUnix } from '@/libs/date-format'
import { constructQuery } from '@core/utils/utils'
import { RevisionEventType } from '@core/revisions/revisions'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    current: {
      data: {},
    },
    elements: [],
    lastOpenedElement: localStorage.getItem('novti-last-opened-element') || null,
  },
  getters: {
    getCurrent: state => state.current,
    getElements: state => state.elements,
    getExternalResources: state => type => state.externalResources.filter(item => item.type === type),
  },
  mutations: {
    SET_CURRENT(state, payload) {
      state.current = {
        snapshot: payload,
        data: { ...payload.data(), id: payload.id },
      }
    },
    SET_ELEMENTS(state, payload) {
      state.elements = payload
    },
    DESTROY_CURRENT(state) {
      state.current = {}
    },
    SET_LAST_OPENED_ELEMENT(state, payload) {
      localStorage.setItem('novti-last-opened-element', payload)

      state.lastOpenedElement = payload
    },
  },
  actions: {
    /**
     * Fetches the tenant's Elements.
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     *
     * @returns {Promise}
     */
    fetchElements({ commit }) {
      return new Promise((resolve, reject) => {
        const { elements } = tenantCtx()

        const q = constructQuery(elements, 'name', {
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(docSnapshot => {
            const elementsList = docSnapshot.docs
              .map(docRef => ({
                id: docRef.id,
                ...docRef.data(),
              }))

            commit('SET_ELEMENTS', elementsList)
            resolve(elementsList)
          })
          .catch(error => {
            reject(error)

            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Elements') }),
            })
          })
      })
    },

    /**
     * Fetches a Element by ID.
     *
     * @param {Object} obj
     * @param {string} id The document ID
     *
     * @returns {Promise}
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { elements } = tenantCtx()
        const docRef = doc(elements, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists() || docSnapshot.get('deletedAt') !== null) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Element') }),
            })

            reject(error)
          })
      })
    },

    /**
     * Updates a Element by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload - The payload
     *
     * @returns {Promise}
     */
    update({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const { elements, elementsStorage } = tenantCtx()
          const docRef = doc(elements, payload.id)

          // Let us see if we have to upload something
          if (payload.newValue !== payload.oldValue) {
            const fileRef = storageRef(
              elementsStorage,
              `${payload.name}.${payload.type}`,
            )

            await uploadString(fileRef, payload.newValue)

            // Sync the values for the participating units
            // and prevent unnecessary Storage updates
            payload.oldValue = payload.newValue
          }

          // Prevent unnecessary Firestore updates
          delete payload.changed

          const { newValue, oldValue, ...fileData } = payload

          updateDoc(docRef, {
            ...fileData,
            updatedAt: getUnix(),
          }).then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_ELEMENTS,
              id: payload.id,
              newValue,
              previousValue: oldValue,
            })

            resolve(true)
          })
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            variant: 'danger',
            body: i18n.t('Something went wrong updating the {title}', { title: i18n.t(`Element ${payload.name}.${payload.type}`) }),
          })

          reject(error)
        }
      })
    },

    /**
     * Deletes a Element by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload - The payload
     *
     * @returns {Promise}
     */
    delete({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const { elements } = tenantCtx()
          const { elementsStorage } = tenantCtx()
          const docRef = doc(elements, payload.id)

          const fileRef = storageRef(
            elementsStorage,
            `${payload.name}.${payload.type}`,
          )

          await deleteObject(fileRef)

          // Soft delete in the DB
          updateDoc(docRef, {
            deletedAt: getUnix(),
          })
            .then(() => {
              store.dispatch('revisions/create', {
                event: RevisionEventType.DELETE_ELEMENTS,
                id: payload.id,
                newValue: {},
                previousValue: payload,
              })

              resolve(true)
            })
            .catch(error => {
              reject(error)
            })
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            variant: 'danger',
            body: i18n.t('Something went wrong deleting the {title}', { title: i18n.t(`Element ${payload.name}.${payload.type}`) }),
          })

          reject(error)
        }
      })
    },

    /**
     * Downloads the content of a Element by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload - The payload used to fetch the file
     *
     * @returns {Promise}
     */
    downloadElementContent({}, payload) {
      return new Promise(async (resolve, reject) => {
        const { elementsStorage } = tenantCtx()
        const fileRef = storageRef(
          elementsStorage,
          `${payload.name}.${payload.type}`,
        )

        getBlob(fileRef)
          .then(fileBlob => {
            resolve(fileBlob)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t(`the content of ${payload.name}.${payload.type}`) }),
            })

            reject(error)
          })
      })
    },
  },
}
