export default [
  // *===============================================---*
  // *--------- USER ACCOUNT SETTINGS ------------------*
  // *===============================================---*
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/account-settings/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      action: 'update',
      resource: 'account_settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
]
