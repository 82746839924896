export default {
  namespaced: true,
  state: {
    tourSteps: { steps: [] },
  },
  getters: {
    getTourSteps: state => state.tourSteps,
  },
  mutations: {
    SET_TOURSTEPS(state, payload) {
      const seenCrud = localStorage.getItem(payload.crud)
      state.tourSteps = { ...payload, crud: payload.crud, seen: seenCrud }
    },
    SET_SEENTOUR(state, payload) {
      localStorage.setItem(payload.crud, payload.seen)
      state.tourSteps = { ...payload, crud: payload.crud, seen: payload.seen }
    },
  },
  actions: {},
}
