import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({

  // You can add your headers here
  // ================================
  baseURL: 'http://localhost:8024/api',
  // headers: {'X-Custom-Header': 'foobar'},
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
