import { getAuth0Instance as authInstance } from '@/plugins/auth0'
import i18n from '@/libs/i18n'

const auth0Roles = {
  admin: process.env.VUE_APP_AUTH0_ADMIN_ROLE_ID,
  user: process.env.VUE_APP_AUTH0_USER_ROLE_ID,
  integrator: process.env.VUE_APP_AUTH0_INTEGRATOR_ROLE_ID,
  maintainer: process.env.VUE_APP_AUTH0_MAINTAINER_ROLE_ID,
  plenipotent: process.env.VUE_APP_AUTH0_PLENIPOTENT_ROLE_ID,
  finance: process.env.VUE_APP_AUTH0_FINANCE_ROLE_ID,
  creator: process.env.VUE_APP_AUTH0_CREATOR_ROLE_ID,
  analyst: process.env.VUE_APP_AUTH0_ANALYST_ROLE_ID,
}

// Silent roles are roles are special tenant-nonspecific system roles
// e.g. like `plenipotent` and `maintainer`
export const silentRoles = [
  auth0Roles.maintainer,
  auth0Roles.plenipotent,
]

export default function base() {
  const loggedInUserRole = authInstance().me.data?.role

  const roleOptions = [
    { label: i18n.t('Admin'), value: auth0Roles.admin },
    { label: i18n.t('User'), value: auth0Roles.user },
    { label: i18n.t('Integrator'), value: auth0Roles.integrator },
    { label: i18n.t('Finance'), value: auth0Roles.finance },
    { label: i18n.t('Creator'), value: auth0Roles.creator },
    { label: i18n.t('Analyst'), value: auth0Roles.analyst },
  ]

  // Following roles are used internally
  if (silentRoles.includes(loggedInUserRole)) {
    roleOptions.push({ label: i18n.t('Maintainer'), value: auth0Roles.maintainer })
    roleOptions.push({ label: i18n.t('Plenipotent'), value: auth0Roles.plenipotent })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'integrator') return 'primary'
    if (role === 'admin') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'user') return 'info'
    if (role === 'plenipotent') return 'danger'
    if (role === 'finance') return 'secondary'
    if (role === 'creator') return 'dark'
    if (role === 'analyst') return 'light'

    return 'primary'
  }

  const resolveAuth0Role = role => {
    if (role === auth0Roles.admin) return 'admin'
    if (role === auth0Roles.integrator) return 'integrator'
    if (role === auth0Roles.maintainer) return 'maintainer'
    if (role === auth0Roles.user) return 'user'
    if (role === auth0Roles.plenipotent) return 'plenipotent'
    if (role === auth0Roles.finance) return 'finance'
    if (role === auth0Roles.creator) return 'creator'
    if (role === auth0Roles.analyst) return 'analyst'

    return ''
  }

  const resolveUserRoleIcon = role => {
    if (role === 'integrator') return 'Edit2Icon'
    if (role === 'admin') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'user') return 'UserIcon'
    if (role === 'plenipotent') return 'ServerIcon'
    if (role === 'finance') return 'DollarSignIcon'
    if (role === 'creator') return 'BookOpenIcon'
    if (role === 'analyst') return 'BarChart2Icon'

    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'PENDING') return 'warning'
    if (status === 'ACTIVE') return 'success'
    if (status === 'INACTIVE') return 'secondary'

    return 'primary'
  }

  const getStatusLabel = input => {
    const statusOptions = [
      { label: i18n.t('Pending'), value: 'PENDING' },
      { label: i18n.t('Active'), value: 'ACTIVE' },
      { label: i18n.t('Inactive'), value: 'INACTIVE' },
    ]

    const status = statusOptions.find(stat => stat.value === input)

    return status ? status.label : null
  }

  return {
    auth0Roles,
    silentRoles,
    roleOptions,
    getStatusLabel,
    resolveAuth0Role,
    resolveUserRoleIcon,
    resolveUserRoleVariant,
    resolveUserStatusVariant,
  }
}
