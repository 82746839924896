import i18n from '@/libs/i18n'

export default [
  {
    path: '/preference-centers/list',
    name: 'preference-centers-list',
    component: () => import('@/views/preference-centers/preference-centers-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Preference centers'),
      action: 'read',
      resource: 'preference_centers',
    },
  },
  {
    path: '/preference-centers/create',
    name: 'preference-centers-create',
    component: () => import('@/views/preference-centers/preference-centers-create/Create.vue'),
    meta: {
      pageTitle: i18n.t('Create preference center'),
      action: 'create',
      resource: 'preference_centers',
      breadcrumb: [
        {
          text: i18n.t('Preference centers'),
          to: '/preference-centers/list',
        },
        {
          text: i18n.t('Create'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/preference-centers/:id/edit',
    name: 'preference-centers-edit',
    component: () => import('@/views/preference-centers/preference-centers-edit/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Preference Center settings'),
      action: 'update',
      resource: 'preference_centers',
      store: {
        subscribe: 'preferenceCenters/SET_CURRENT',
        retrieve: 'preferenceCenters/getCurrentData',
      },
      breadcrumb: [
        {
          text: i18n.t('Preference Centers'),
          to: '/preference-centers/list',
        },
        {
          text: '',
          field: 'title',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
]
